import { useEffect, useState } from 'react'
import { Body } from '../../Layouts/Body'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { appApi } from '../../../services/appApi'
import { TextBox } from './Components/TextBox'
import { Main, FlexHead } from './styles'
import { iPerguntasFrequentes } from '../../../interfaces'
import { Flex } from '@chakra-ui/react'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { useNavigate } from 'react-router-dom'
import { parseJwt } from '../../../services/token'
import { SearchCmp } from '../../../components/SearchCmp'

export const PerguntasFrequentesColab: React.FC = () => {
  const nav = useNavigate()
  const [Perguntas, setPerguntas] = useState<iPerguntasFrequentes[]>([])
  const [Texto, setTexto] = useState('')

  function getPerguntas(): void {
    appApi.get('PerguntasFrequentes')
      .then(res => setPerguntas(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getPerguntas()
  }, [])
  return (
    <Body>
      <Flex justifyContent={'space-between'}>
        <h1>Perguntas frequentes</h1>
        {(parseJwt().adm === 'True') && (
            <ButtonCmp onClick={() => nav('/Usuario/PerguntasFrequentes/Editar')} VarColor='Green2'>Editar perguntas</ButtonCmp>
        )}
      </Flex>
      <WhiteContainer margin='1rem 0'>
        <Main>
          <img src='https://sigah.blob.core.windows.net/onboard/93e163ff-dc29-4ffb-9c95-cd44d3a2d68d.png' />
          <section>
            <FlexHead>
              <SearchCmp
                EnterAction={() => {}}
                OnChange={(e) => setTexto(e)}
                placeholder='Buscar'
                width='18rem'
              />
            </FlexHead>
            {
              Perguntas.filter((e: iPerguntasFrequentes) => {
                if (Texto === '') {
                  return e
                } else if (e.titulo.toLowerCase().includes(Texto.toLowerCase())) {
                  return e
                }
                return null
              }).map((e: iPerguntasFrequentes, i) => {
                return (
                  <TextBox
                    key={i}
                    isFirst={i === 0}
                    Titulo={e.titulo}
                    Pergunta={e.descricao}
                  />
                )
              })
            }
          </section>
        </Main>
      </WhiteContainer>
    </Body>
  )
}
