/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { v4 as uuidv4 } from 'uuid'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Divider, Flex, FormControl, FormLabel, Grid, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FindUser } from '../../../components/FindBox'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { iColaboradorBase, iNomeId } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { AcaoPDI } from '../components/Acao'
import { ModalSucess } from '../components/ModalSucess'
import { CardPDI, OutlineContainer, Subtitulo, ComentariosContainer } from './styles'
import { parseJwt } from '../../../services/token'
import { ModalDelete } from '../../../components/ModalDelete'
import { FaBrain, FaPlus } from 'react-icons/fa'
import { AxiosResponse } from 'axios'
import { PropInvalida, useQuery } from '../../../Utils/Helper'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { BrainIA } from '../../../assets/BrainIA'
import { SelectCmp } from '../../../components/SelectCmp'
import { InfoTooltipCmp } from '../../../components/InfoTooltipCmp'
import { ModalRecomendacoesIA } from '../components/ModalRecomendacoesIA'
import { ModalResponsavel } from '../components/ModalResponsavel'

export interface iModelAcao {
  id: string
  colaboradorAcao: boolean
  dataInicio?: string
  dataFim: string
  hora?: string
  assunto?: string
  competenciaId?: string
  titulo?: string
  descricao?: string
  motivo?: string
  tipo: number
  status: number
  temErro?: boolean
  atualizacao?: iComentario[]
  progressos?: iProgressoXAcao[]
}

export interface iProgressoXAcao {
  acaoId: string
  progresso: number
  comentario?: string
  data?: string
  hora?: string
  colaborador?: iColaboradorBase
}

interface iModel {
  status: number
  origem: number
  participante?: iAvaliadoPDI
  responsavel?: iAvaliadoPDI
  acoes: iModelAcao[]
  canEdit: boolean
  avaliacoes: iAvaliacoes[]
  indicativa: boolean
}

interface iAvaliadoPDI {
  id: string
  nome: string
  area: string
  avatar: string
}

export interface iPostPDI {
  responsavelId?: string
  colaboradorId?: string
  origem: number
  acoes?: iPostAcao[]
  origemId?: string
}

export interface iPostAcao {
  id: string
  assunto?: string
  competenciaId?: string
  dataInicio?: string
  dataFim?: string
  horas?: number
  permitirAlteracao: boolean
  titulo: string
  descricao: string
  motivo: string
}

export interface iComentario {
  data: string
  horario: string
  autor: string
  tipoComentario?: number
  comentario?: string
}

export interface iPDIPermissoes {
  colaboradorFazerProprioPDI: boolean
  gestorAtribuirPDI: boolean
}

export interface iAvaliacoes {
  colaboradorId: string
  nota: any
  notas: iNotas[]
  respondido: boolean
  encerrado: boolean
  id: string
  data: string
  horario: string
  autor: string
  comentario?: string
}

export interface iNotas {
  acaoId: string
  nota: number
}

interface iPesquisaIa {
  lista: Array<{ id: string, name: string }>
  ultimaAvaliacaoId: string
}

export const CriarPDI: React.FC = () => {
  const query = useQuery()

  const avaliacaoId = query.get('avaliacaoId')
  const etapaId = query.get('etapaId')
  const responsavelId = query.get('responsavelId')
  const colaboradorId = query.get('colaboradorId')
  const IA = query.get('ia')

  const origemExterna = avaliacaoId || etapaId

  const { adm, role } = parseJwt()
  const { pdiId } = useParams<{ pdiId: string }>()
  const { pathname } = useLocation()
  const toast = useToast()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>({
    origem: 0,
    acoes: [],
    status: 0,
    avaliacoes: [],
    canEdit: true,
    indicativa: false
  })

  const [PostModel, setPostModel] = useState<iPostPDI>({
    origem: 0
  })

  const [PesquisasIa, setPesquisasIa] = useState<iPesquisaIa>({ lista: [], ultimaAvaliacaoId: '' })
  const [ModalSucessIsOpen, setModalSucessIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalIAIsOpen, setModalIAIsOpen] = useState(false)
  const [ModalDeletePDIIsOpen, setModalDeletePDIIsOpen] = useState(false)
  const [ModalResponsavelIsOpen, setModalResponsavelIsOpen] = useState(false)
  const [Permissoes, setPermissoes] = useState<iPDIPermissoes>()
  const [MeuPDI, setMeuPDI] = useState('1')
  const [AcaoId, setAcaoId] = useState<string>()

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Responsaveis, setResponsaveis] = useState<iNomeId[]>([])
  const [Pesquisas, setPesquisas] = useState<iNomeId[]>([])
  const [Competencias, setCompetencias] = useState<iNomeId[]>([])
  const [Comentarios, setComentarios] = useState<iComentario[]>([])

  const [ResponsavelIsInvalid, setResponsavelIsInvalid] = useState(false)
  const [ColaboradorIsInvalid, setColaboradorIsInvalid] = useState(false)

  const [IsLoading, setIsLoading] = useState(false)

  const handleCheckMeuPDI = (value: string): void => {
    if (value === '2') setPesquisas([])
    else if (value === '1') getPesquisas(parseJwt().cid)
    setMeuPDI(value)
    if (value === '1') {
      setPostModel(
        {
          ...PostModel,
          colaboradorId: undefined,
          responsavelId: undefined
        })
    }
  }

  function SelecionarResponsavel(id: string): void {
    setPostModel({ ...PostModel, responsavelId: id })
  }

  function SelecionarParticipante(id: string): void {
    if (parseJwt().adm !== 'True') {
      setPostModel({ ...PostModel, responsavelId: parseJwt().cid, colaboradorId: id })
      return
    }
    setPostModel({ ...PostModel, colaboradorId: id })
  }

  function ObterColaboradores(): void {
    appApi.get('PDI/Colaborador')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterResponsaveis(): void {
    appApi.get('PDI/Colaborador?altaLideranca=true')
      .then(res => setResponsaveis(res.data))
      .catch(err => console.log(err))
  }

  function ObterInfoIA(): void {
    appApi.get(`PesquisaPercepcao/IaInfos/${colaboradorId}`)
      .then(res => setPesquisasIa(res.data))
      .catch(err => console.log(err))
  }

  function ObterCompetencias(): void {
    appApi.get('Competencia/Select')
      .then(res => setCompetencias(res.data))
      .catch(err => console.log(err))
  }

  function AdicionarAcao(): void {
    const acoes = [...Model.acoes]
    acoes.unshift({
      id: uuidv4(),
      assunto: '',
      colaboradorAcao: false,
      dataFim: '',
      tipo: 0,
      status: 0
    })
    setModel({ ...Model, acoes: acoes })
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setAcaoId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setAcaoId(undefined)
  }

  function RemoverAcao(id: string): void {
    const copy = [...Model.acoes.filter(e => e.id !== id)]
    setModel({ ...Model, acoes: [] })
    setModel({ ...Model, acoes: copy })
  }

  function AtualizarAcao(acao: iModelAcao): void {
    const copy = [...Model.acoes]
    const pos = copy.findIndex(e => e.id === acao.id)
    if (pos !== -1) {
      copy[pos] = acao
      setModel({ ...Model, acoes: copy })
    }
  }

  function EnviarPDI(): void {
    setColaboradorIsInvalid(false)
    setResponsavelIsInvalid(false)

    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.colaboradorId?.length === 0 && MeuPDI === '2') {
      setColaboradorIsInvalid(true)
      toast({
        title: 'Defina um colaborador para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.responsavelId?.length === 0 && MeuPDI === '2') {
      setResponsavelIsInvalid(true)
      toast({
        title: 'Defina um responsável para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostPDI = {
      colaboradorId: PostModel.colaboradorId,
      origem: PostModel.origem,
      responsavelId: PostModel.responsavelId,
      origemId: avaliacaoId ?? etapaId ?? PostModel.origemId ?? undefined,
      acoes: Model.acoes.map((e) => {
        const model: iPostAcao = {
          id: e.id,
          dataFim: e.dataFim,
          descricao: e.descricao ?? '',
          motivo: e.motivo ?? '',
          permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
          titulo: e.titulo ?? '',
          assunto: e.assunto,
          competenciaId: e.competenciaId,
          dataInicio: e.dataInicio,
          horas: parseInt(e.hora ?? '0')
        }
        return model
      })
    }

    appApi.post('PDI?salvar=false', form)
      .then(() => {
        toast({
          title: 'PDI cadastrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        setModalSucessIsOpen(true)
      })
      .catch(err => console.log(err))
  }

  function DeletarPDI(): void {
    appApi.delete(`PDI/${pdiId}`).then(() => {
      toast({
        title: 'PDI removido com sucesso!',
        position: 'top-right',
        status: 'success',
        duration: 5000,
        isClosable: false
      })
      nav(-1)
    }).catch(err => err)
  }

  function SalvarSair(): void {
    if (Model.acoes?.length === 0) {
      toast({
        title: 'Não selecionou nenhuma ação',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.colaboradorId?.length === 0 && MeuPDI === '2') {
      toast({
        title: 'Defina um colaborador para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (PostModel.responsavelId?.length === 0 && MeuPDI === '2') {
      toast({
        title: 'Defina um responsável para este PDI',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostPDI = {
      colaboradorId: PostModel.colaboradorId,
      origem: PostModel.origem,
      responsavelId: PostModel.responsavelId,
      origemId: avaliacaoId ?? undefined ?? PostModel.origemId,
      acoes: Model.acoes.map((e) => {
        const model: iPostAcao = {
          id: e.id,
          dataInicio: PropInvalida(e.dataInicio) ? undefined : e.dataInicio,
          dataFim: PropInvalida(e.dataFim) ? undefined : e.dataFim,
          descricao: e.descricao ?? '',
          motivo: e.motivo ?? '',
          permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
          titulo: e.titulo ?? '',
          assunto: e.assunto,
          competenciaId: e.competenciaId,
          horas: parseInt(e.hora ?? '0')
        }
        return model
      })
    }

    appApi.post('PDI/?salvar=true&sair=true', form)
      .then(() => {
        toast({
          title: 'PDI salvo com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function AtualizarPDI(): void {
    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostAcao[] = Model.acoes.map((e) => {
      const model: iPostAcao = {
        id: e.id,
        dataInicio: PropInvalida(e.dataInicio) ? undefined : e.dataInicio,
        dataFim: PropInvalida(e.dataFim) ? undefined : e.dataFim,
        descricao: e.descricao ?? '',
        motivo: e.motivo ?? '',
        permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
        titulo: e.titulo ?? '',
        assunto: e.assunto,
        competenciaId: e.competenciaId,
        horas: parseInt(e.hora ?? '0')
      }
      return model
    })

    appApi.put(`PDI/${pdiId as string}?salvar=false`, form)
      .then(() => {
        toast({
          title: 'Atualizações salvas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        setModalSucessIsOpen(true)
      })
      .catch(err => console.log(err))
  }

  function AtualizarSair(): void {
    if (VerificarErrosAcoes()) {
      toast({
        title: 'Busque por ações de cor vermelha e verifique se todos os campos foram preenchidos',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const form: iPostAcao[] = Model.acoes.map((e) => {
      const model: iPostAcao = {
        id: e.id,
        dataInicio: PropInvalida(e.dataInicio) ? undefined : e.dataInicio,
        dataFim: PropInvalida(e.dataFim) ? undefined : e.dataFim,
        descricao: e.descricao ?? '',
        motivo: e.motivo ?? '',
        permitirAlteracao: MeuPDI === '1' ? true : e.colaboradorAcao,
        titulo: e.titulo ?? '',
        assunto: e.assunto,
        competenciaId: e.competenciaId,
        horas: parseInt(e.hora ?? '0')
      }
      return model
    })

    appApi.put(`PDI/${pdiId as string}/?salvar=true`, form)
      .then(() => {
        toast({
          title: 'Atualizações salvas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function VerificarErrosAcoes(): boolean {
    const comErros = Model.acoes.filter(e =>
      e.dataFim === '' || e.dataFim === undefined ||
      (e.dataFim === null) ||
      e.tipo === 0 ||
      (e.tipo === 1 && e.competenciaId === undefined) ||
      (e.tipo === 2 && (e.assunto === '' || e.assunto === undefined)) ||
      (!e.colaboradorAcao && (e.dataInicio === undefined || e.dataInicio === null || e.dataInicio === ''))
    )
    const copy = [...Model.acoes]
    comErros.forEach((e) => {
      copy[copy.indexOf(e)] = { ...e, temErro: true }
      setModel({ ...Model, acoes: copy })
    })
    return comErros.length > 0
  }

  function ActionClick(sair: boolean): void {
    if (Model.acoes?.length === 0) {
      toast({
        title: 'Não selecionou nenhuma ação',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    const criar = pathname.includes('Criar')
    console.log(sair)
    console.log(criar)

    if (criar && !sair) {
      EnviarPDI()
    } else if (criar && sair) {
      SalvarSair()
    } else if (!criar && !sair) {
      AtualizarPDI()
    } else if (!criar && sair) {
      AtualizarSair()
    }
  }

  function ObterPDI(): void {
    appApi.get(`PDI/${pdiId as string}/Editar`)
      .then((res: AxiosResponse<iModel>) => {
        setModel(res.data)
        const comentarios = res.data.acoes
          .filter(e => e.atualizacao?.map(r => r))
          .map(e => e.atualizacao)
          .flatMap(e => e ?? []) as unknown as iComentario[]

        setComentarios(comentarios)
      })
      .catch(err => console.log(err))
  }

  function ObterPermissoesPDI(): void {
    appApi.get('PDI/Permissoes')
      .then((res: AxiosResponse<iPDIPermissoes>) => {
        setPermissoes(res.data)
        setMeuPDI(res.data.colaboradorFazerProprioPDI ? '1' : '2')
      })
      .catch(err => console.log(err))
  }

  function ObterTituloBtn(): string {
    if (pathname.includes('Criar') && MeuPDI === '1') {
      return 'Enviar PDI para aprovação'
    } else if (pathname.includes('Criar') && MeuPDI === '2') {
      return 'Enviar PDI para ser cadastrado'
    } else if (pathname.includes('Editar')) {
      return 'Enviar aprovação'
    }
    return ''
  }

  function ObterRecomendacao(avaliacaoId: string): void {
    appApi.get(`PDI/${avaliacaoId}/Recomendacoes`)
      .then(res => {
        const data: iModel = res.data
        setModel({
          ...Model,
          origem: data.origem,
          acoes: data.acoes,
          participante: data.participante,
          indicativa: data.indicativa
        })
      })
      .catch(err => console.log(err))
  }

  function getPesquisas(colabId: string): void {
    appApi.get(`PDI/Pesquisas?colaboradorId=${colabId}`)
      .then(res => { if (res.data?.length > 0) { setPesquisas(res.data) } })
      .catch(err => console.log(err))
  }

  function ChangeResponsavel(responsavelId: string, email: boolean): void {
    appApi.patch(`PDI/${pdiId}/Responsavel?novoResponsavelId=${responsavelId}&email=${email}`)
      .then(() => {
        toast({
          title: 'Responsável alterado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
      .finally(() => { setModalResponsavelIsOpen(false); ObterPDI() })
  }

  function ObterRecomendacaoPorIA(id: string, replace: boolean, gerarNovamente?: boolean): void {
    setIsLoading(true)
    appApi.get(`PDI/IA/${id}/Acoes?gerarNovamente=${gerarNovamente ? 'true' : 'false'}`)
      .then(res => {
        if (res.status === 200) {
          const acoes: iModelAcao[] = res.data
          if (replace) {
            const acoesFiltradas = [...Model.acoes].filter(e => !acoes.some(t => t.competenciaId === e.competenciaId))
            setModel({
              ...Model,
              acoes: acoesFiltradas.concat(acoes)
            })
          } else {
            setModel({
              ...Model,
              acoes: [...Model.acoes].concat(acoes)
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setModalIAIsOpen(false)
        setIsLoading(false)
        toast({
          title: 'Recomendações geradas com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top'
        })
      })
  }

  useEffect(() => {
    if (pathname.includes('Criar') && colaboradorId && responsavelId) {
      setPostModel({
        ...PostModel,
        colaboradorId: colaboradorId ?? '',
        responsavelId: responsavelId ?? '',
        origemId: avaliacaoId ?? etapaId ?? '',
        origem: avaliacaoId ? 1 : etapaId ? 4 : 1
      })
    }
    if (avaliacaoId && IA?.toLowerCase() === 'true') {
      ObterRecomendacaoPorIA(avaliacaoId ?? '', false)
    } else if (avaliacaoId) {
      ObterRecomendacao(avaliacaoId)
    }

    ObterCompetencias()
    ObterResponsaveis()
    if ((adm === 'True' || role === '1' || role === '2')) {
      ObterColaboradores()
    }
    if (pdiId) {
      ObterPDI()
    }
    ObterPermissoesPDI()
    ObterInfoIA()
  }, [])

  useEffect(() => {
    if (PostModel.colaboradorId) {
      getPesquisas(PostModel.colaboradorId)
    }
  }, [PostModel.colaboradorId])

  useEffect(() => {
    getPesquisas(parseJwt().cid)
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta ação?'
        onRequestClose={onCloseModalDelete}
        center
        onConfirm={() => RemoverAcao(AcaoId ?? '')}
      />

      <ModalDelete
        isOpen={ModalDeletePDIIsOpen}
        message='Deseja realmente excluir este PDI?'
        center
        onRequestClose={() => setModalDeletePDIIsOpen(false)}
        onConfirm={DeletarPDI}
      />

      {PesquisasIa.ultimaAvaliacaoId !== null && <ModalRecomendacoesIA
        isOpen={ModalIAIsOpen}
        onRequestClose={() => setModalIAIsOpen(false)}
        onConfirm={(e, novasRecomendacoes) => ObterRecomendacaoPorIA(e, true, novasRecomendacoes)}
        pesquisas={PesquisasIa.lista}
        ultimaAvaliacaoId={PesquisasIa.ultimaAvaliacaoId}
        isLoading={IsLoading}
      />}

      <ModalResponsavel
        isOpen={ModalResponsavelIsOpen}
        onRequestClose={() => setModalResponsavelIsOpen(false)}
        onConfirm={(id, email) => ChangeResponsavel(id, email)}
      />

      <ModalSucess
        isOpen={ModalSucessIsOpen}
        onRequestClose={() => nav('/Desempenho/PDI/Dashboard/Individual')}
      >
        {(pathname.includes('Criar') && MeuPDI === '2') && (
            <>
              <h2>PDI enviado para o</h2>
              <h2>colaborador !</h2>
            </>

        )}

        {(pathname.includes('Criar') && MeuPDI === '1') && (
            <>
              <h2>PDI enviado para o</h2>
              <h2>aprovação !</h2>
            </>

        )}

        {(pathname.includes('Editar')) && (
            <>
              <h2>Atualização enviada!</h2>
            </>
        )}
      </ModalSucess>

      <WhiteContainer>
        <Flex marginBottom={'1rem'} justifyContent={'space-between'}>
          <h1>Desempenho {'>'} {pathname.includes('Criar') ? 'Criando' : 'Editando'} PDI</h1>
          {!pathname.includes('Criar') && parseJwt().adm === 'True' && <ButtonCmp VarColor='Green2' onClick={() => setModalResponsavelIsOpen(true)}>Alterar responsável</ButtonCmp>}
        </Flex>
        <OutlineContainer>
          {
            (pathname.includes('Criar') && !origemExterna) && (
              <>
                <Flex justifyContent={'space-between'} gap={'.75rem'}>
                  <Flex gap={'.5rem'} alignItems={'center'}>
                    <RadioGroup display='flex'
                      justifyContent={'center'}
                      size={'sm'}
                      onChange={(e) => handleCheckMeuPDI(e)}
                      value={MeuPDI}
                      marginRight={'.5rem'}
                    >
                      <Stack gap={'.5rem'} direction='row'>
                        {Permissoes?.colaboradorFazerProprioPDI && <Radio borderColor={'var(--Gray4)'} value='1'>Meu PDI</Radio>}
                        {((Permissoes?.gestorAtribuirPDI && (role === '1' || role === '2')) || adm === 'True') && (
                          <Radio borderColor={'var(--Gray4)'} value='2'>PDI para o</Radio>
                        )}
                      </Stack>
                    </RadioGroup>
                    {MeuPDI === '2' && (
                      <Flex>
                        <FindUser
                         minWInput={'14rem'}
                          placeholder='Selecione um colaborador'
                          lista={Colaboradores}
                          onChoice={SelecionarParticipante}
                          isInvalid={ColaboradorIsInvalid}
                        />
                      </Flex>
                    )}
                  </Flex>
                  <ElementoTooltipCmp
                    label={PropInvalida(PostModel.origemId) ? 'Selecione uma pesquisa!' : ''}
                  >
                    <ButtonCmp
                      bgGradient={'var(--DegradeAzul)'}
                      isLoading={IsLoading}
                      leftIcon={<BrainIA theme='white' size={24}/>}
                      isDisabled={PropInvalida(PostModel.origemId)}
                      onClick={() => ObterRecomendacaoPorIA(PostModel.origemId ?? '', false)}
                    >Gerar PDI através da IA</ButtonCmp>
                  </ElementoTooltipCmp>
                </Flex>
                <Grid gridTemplateColumns={'repeat(3, 1fr)'} marginTop={'1rem'} gap={'.75rem'}>
                  <FormControl>
                    <SelectCmp
                      title='Origem do PDI'
                      value={PostModel.origem.toString()}
                      OnChange={(e) => { setPostModel({ ...PostModel, origem: parseInt(e) }) }}
                      borderColor={'var(--Gray4)'}
                    >
                      <option value={'0'}>Pesquisa</option>
                      <option value={'1'}>Feedback</option>
                      <option value={'2'}>Outra</option>
                      {etapaId && <option value={'4'}>Onboard</option>}
                    </SelectCmp>
                  </FormControl>

                  {(MeuPDI === '2' && parseJwt().adm === 'True') &&
                    (
                      <FormControl>
                        <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Responsável pela aprovação</FormLabel>
                        <FindUser
                          placeholder='Selecione um colaborador'
                          lista={Responsaveis}
                          onChoice={SelecionarResponsavel}
                          isInvalid={ResponsavelIsInvalid}
                        />
                      </FormControl>
                    )}

                  {pathname.includes('Criar') &&
                    <Flex flexDir={'column'}>
                      <FormControl>
                        <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} display={'flex'} gap={'.5rem'} alignItems={'center'}>
                          Selecione uma pesquisa
                          <InfoTooltipCmp label={'Só são apresentadas pesquisas que você ou o participante participaram como avaliado'} VarColor='Green2'/>
                        </FormLabel>
                        <SelectCmp
                          OnChange={(e) => setPostModel({ ...PostModel, origemId: e })}
                          borderColor={'var(--Gray4)'}
                        >
                          <option value={''}>Nenhum</option>
                          {Pesquisas?.map((e) => {
                            return (
                              <option value={e.id}>{e.nome}</option>
                            )
                          })}
                        </SelectCmp>
                      </FormControl>
                      <Text fontSize={'0.75rem'}></Text>
                    </Flex>
                  }
                </Grid>
              </>
            )
          }

          <Flex borderBottom={pathname.includes('Editar') ? '1px solid var(--c5)' : ''} pb={pathname.includes('Editar') ? '1.75rem' : ''} mb={pathname.includes('Editar') ? '1rem' : ''} justifyContent={'space-between'}>
            <Flex gap={'2rem'}>
              {(Model.participante) && (
                <Flex flexDir={'column'} fontSize={'14px'}>
                  <FormLabel fontWeight={700} fontSize={'14px'}>Dono {!Model.responsavel && 'e responsável'} do PDI</FormLabel>
                  <CardPDI>
                    <Avatar size={'md'} marginRight={'1rem'} src={Model.participante.avatar} name={Model.participante.nome} />
                    <Flex flexDir={'column'}>
                      <Flex gap={'.25rem'}>Nome: <strong>{Model.participante.nome}</strong></Flex>
                      <Flex gap={'.25rem'}>Área: <strong>{Model.participante.area}</strong></Flex>
                    </Flex>
                  </CardPDI>
                </Flex>
              )}

              {(Model.responsavel) && (
                <>
                  <Divider orientation='vertical' borderColor={'var(--c6)'}/>
                  <Flex flexDir={'column'} fontSize={'14px'}>
                    <FormLabel fontWeight={700} fontSize={'14px'}>Responsável do PDI</FormLabel>
                    <CardPDI>
                      <Avatar size={'md'} marginRight={'1rem'} src={Model.responsavel.avatar} name={Model.responsavel.nome} />
                      <Flex flexDir={'column'}>
                        <Flex gap={'.25rem'}>Nome: <strong>{Model.responsavel.nome}</strong></Flex>
                        <Flex gap={'.25rem'}>Área: <strong>{Model.responsavel.area}</strong></Flex>
                      </Flex>
                    </CardPDI>
                  </Flex>
                </>
              )}
            </Flex>

            {!pathname.includes('Criar') && (pathname.includes('Editar') || origemExterna) &&
              <Flex flexDir={'column'} bg={'var(--RosaOpacoBg)'} p={'.5rem 1rem'} fontSize={'14px'} border={'1px solid var(--Rosa)'} justifyContent={'center'} gap={'.15rem'} borderRadius={'.25rem'} boxShadow={'var(--SombraBtns)'}>
                <Flex gap={'.3rem'} alignItems={'center'}>
                  <Flex fontWeight={'700'}>Origem do PDI: </Flex>
                  <Flex alignItems={'center'}>
                    {Model.origem === 0 && 'Pesquisa'}
                    {Model.origem === 1 && 'Feedback'}
                    {Model.origem === 2 && 'Outra'}
                    {Model.origem === 4 && 'Onboard'}
                  </Flex>
                </Flex>
                <Flex gap={'.3rem'} alignItems={'center'}>
                  <Flex fontWeight={'700'}>Status do PDI: </Flex>
                  <Flex alignItems={'center'}>
                    {Model.status === 0 && 'Em edição'}
                    {Model.status === 1 && 'Aguardando aprovação'}
                    {Model.status === 2 && 'Aguardando alteração'}
                    {Model.status === 3 && 'Aprovado'}
                    {Model.status === 4 && 'Aguardando inicio'}
                    {Model.status === 5 && 'Em andamento'}
                    {Model.status === 6 && 'Atrasado'}
                    {Model.status === 7 && 'Concluido'}
                  </Flex>
                </Flex>
                <Flex gap={'.3rem'} alignItems={'center'}>
                  <Flex fontWeight={'700'}>Avaliações realizadas: </Flex>
                  <Flex alignItems={'center'}>
                    {Model?.avaliacoes?.length ?? 'Nenhuma'} realizada(s)
                  </Flex>
                </Flex>
              </Flex>
            }

            {pathname.includes('Criar') && Model.indicativa &&
              <ElementoTooltipCmp
                label={IA === 'true' ? 'Ações já geradas!' : ''}
              >
                <ButtonCmp
                  bgGradient={'var(--DegradeAzul)'}
                  fontWeight='500'
                  isLoading={IsLoading}
                  leftIcon={<BrainIA theme='white' size={24}/>}
                  isDisabled={IA === 'true'}
                  onClick={() => ObterRecomendacaoPorIA(PostModel.origemId ?? '', true)}
                >Gerar PDI através da IA</ButtonCmp>
              </ElementoTooltipCmp>
            }
          </Flex>

          {!pathname.includes('Editar') &&
              <Flex flexDir={'column'} mt={'1.5rem'}>
                <Flex justifyContent={'space-between'} alignItems={'end'} mt={pathname.includes('Editar') ? '-1rem' : ''} mb={'.5rem'}>
                  <Flex flexDir={'column'}>
                    <Subtitulo>Descreva ações práticas que você irá realizar para trabalhar neste PDI</Subtitulo>
                    <Text fontSize={'14px'} fontWeight={'400'} color={'var(--c7)'}>Os campos destacados e com * são de preenchimento obrigatório</Text>
                  </Flex>
                  <Flex gap={'.5rem'}>
                    {(pathname.includes('Criar') && origemExterna) && PesquisasIa.ultimaAvaliacaoId !== null && <ButtonCmp onClick={() => setModalIAIsOpen(true)} leftIcon={<FaBrain />} VarColor='Rosa' isDisabled={!Model.canEdit}>Gerar recomendações por IA</ButtonCmp>}
                    <ButtonCmp onClick={AdicionarAcao} leftIcon={<FaPlus />} VarColor='Green2' isDisabled={!Model.canEdit}>Adicionar outra ação</ButtonCmp>
                  </Flex>
                </Flex>
                <Flex marginTop={'.5rem'} flexDir={'column'} gap={'1rem'}>
                  {
                    Model.acoes.map((e, i) => {
                      return (
                        <AcaoPDI
                          key={i}
                          onUpdate={AtualizarAcao}
                          onRemove={onOpenModalDelete}
                          avaliacoes={Model.avaliacoes}
                          acao={e}
                          canEdit={Model.canEdit}
                          competencias={Competencias}
                          meuPDI={MeuPDI === '1'}
                          position={i}
                          OnReload={() => ObterPDI()}
                        />
                      )
                    })
                  }
                </Flex>
              </Flex>
          }

          {
            pathname.includes('Editar') && (
              <>
                <Flex flexDir={'column'} marginTop={'1rem'}>
                  <Flex justifyContent={'space-between'} alignItems={'end'} mt={'-1rem'} mb={'.5rem'}>
                    <Flex flexDir={'column'}>
                      <Subtitulo>Descreva ações práticas que você irá realizar para trabalhar neste PDI</Subtitulo>
                      <Text fontSize={'14px'} fontWeight={'400'} color={'var(--c7)'}>Os campos destacados e com * são de preenchimento obrigatório</Text>
                    </Flex>
                    <ButtonCmp onClick={AdicionarAcao} leftIcon={<FaPlus />} VarColor='Green2' isDisabled={!Model.canEdit}>Adicionar outra ação</ButtonCmp>
                  </Flex>
                  <Flex marginTop={'.5rem'} flexDir={'column'} gap={'1rem'}>
                    {
                      Model.acoes.map((e, i) => {
                        return (
                          <AcaoPDI
                            key={i}
                            canEdit={Model.canEdit}
                            onUpdate={AtualizarAcao}
                            onRemove={onOpenModalDelete}
                            avaliacoes={Model.avaliacoes}
                            acao={e}
                            competencias={Competencias}
                            meuPDI={MeuPDI === '1'}
                            position={i}
                            OnReload={() => ObterPDI()}
                          />
                        )
                      })
                    }
                  </Flex>
                </Flex>

                {Model?.avaliacoes !== null && (
                  <Flex direction={'column'} mt={5}>
                    <h3 style={{ marginBottom: '.5rem' }}>Comentários de avaliação</h3>
                    {Model.avaliacoes.slice(0, 1)?.map((e, i) => {
                      return (
                        <ComentariosContainer key={i}>
                          <strong>{e.comentario}</strong>
                          <span>Enviado por {e.autor} em {e.data} às {e.horario}</span>
                        </ComentariosContainer>
                      )
                    })}
                    {Model.avaliacoes.length > 1 && (
                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} borderRadius={'1.25rem'} px={'0'}>
                            <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.5rem'} w={'full'} justifyContent={'end'} alignItems={'center'}>
                              Ver mais comentários <Flex alignItems={'center'} justifyContent={'center'} w={'1.5rem'} h={'1.5rem'} bg={'var(--Azul)'} color={'white'} borderRadius={'50%'} fontSize={'14px'} textAlign={'center'}>{Model.avaliacoes.length - 1}</Flex>
                              <AccordionIcon />
                            </Flex>
                          </AccordionButton>
                          <AccordionPanel gap={'1rem'} p={'0'}>
                          {Model.avaliacoes.slice(1)?.map((e, i) => {
                            return (
                              <ComentariosContainer key={i}>
                                <strong>{e.comentario}</strong>
                                <span>Enviado por {e.autor} em {e.data} às {e.horario}</span>
                              </ComentariosContainer>
                            )
                          })}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </Flex>
                )}

                {Comentarios?.length === 0 && Model?.avaliacoes === null && (
                  <h3 style={{ marginTop: '1.5rem' }}>Não foram realizados comentários</h3>
                )}
              </>
            )
          }
          {/* {(!Model.acoes.length) && (
            <Flex justifyContent={'end'} mt={'.75rem'}>
              <ButtonCmp onClick={AdicionarAcao} leftIcon={<FaPlus />} VarColor='Green2' isDisabled={!Model.canEdit}>Adicionar outra ação</ButtonCmp>
            </Flex>
          )} */}
        </OutlineContainer>
        <Flex marginTop={(Comentarios?.length === 0 && Model?.avaliacoes === null) ? '' : '1rem'} gap={'.5rem'} justifyContent='end'>

          {Model.acoes.length > 0 && (pathname.includes('Criar') || Model.canEdit) && (
            <ButtonCmp
            VarColor={'Rosa'}
            onClick={() => ActionClick(true)}
          >Salvar e sair</ButtonCmp>
          )}

          {Model.acoes.length > 0 && (pathname.includes('Criar') || Model.canEdit) && (
            <ElementoTooltipCmp label={Model.acoes.some(e => e.status === 2) && Model.participante?.id !== parseJwt().cid ? 'Aguardando as alterações do colaborador!' : ''}>
              <ButtonCmp
                onClick={() => ActionClick(false)}
                isDisabled={Model.acoes.some(e => e.status === 2) && Model.participante?.id !== parseJwt().cid}
                VarColor='Green2'
              >{ObterTituloBtn()}</ButtonCmp>
            </ElementoTooltipCmp>
          )}

          {pathname.includes('Editar') && Model.acoes.length === 0 &&
            <ButtonCmp
              VarColor='Red'
              size={'lg'}
              onClick={() => setModalDeletePDIIsOpen(true)}
            >Exluir PDI</ButtonCmp>
          }
        </Flex>
      </WhiteContainer>
    </Body>
  )
}
