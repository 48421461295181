/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Flex } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaArrowCircleDown, FaArrowCircleRight, FaCheckCircle, FaCopy, FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { SwitchCmp } from '../../../../../components/SwitchCmp'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iCompetencia, iQuestao } from '../../../../../interfaces'
import { CollapseText, ColumnIcons, CompetenciaContent, CompetenciaLine, CompetenciaMenu, Container, ImgText, SectionQuestoes, StyledQuestion } from './styles'

interface CompetenciaProps {
  competencia: iCompetencia
  onModalQuestao: (competenciaId: string, questaoId?: string, duplicar?: boolean) => void
  onOpenModalDelete: (competenciaId: string, questaoId: string) => void
  Edit: () => void
}

export const Competencia: React.FC<CompetenciaProps> = ({ onModalQuestao, onOpenModalDelete, Edit, competencia }) => {
  const { ctxFormulario, SelectCompetencia, SelectQuestao, SetQuestaoObrigatoria } = useFormulario()
  const [isOpen, setisOpen] = useState(false)
  const selecionadas = ctxFormulario.selecoes.find(e => e.competenciaId === competencia.id)
  useEffect(() => {
    setisOpen(competencia.questoes.filter((e: iQuestao) => {
      if (selecionadas?.questoesId.find(r => r === e.id) !== undefined) {
        return e
      }
      return null
    }).length === 0)
  }, [])
  return (
    <Container>
      <CompetenciaMenu>
        <ImgText>
          <img src={competencia.icone} />
          <span>{competencia.nome}</span>
        </ImgText>
        <ColumnIcons>
          {
            (!competencia.doSistema) && (
              <span onClick={() => {
                Edit()
              }} style={{ color: 'var(--Azul)' }}><FaEdit style={{ marginRight: '.125rem' }}
                />Editar</span>
            )
          }
          {
            (!competencia.discursiva) && (
              <span onClick={() => SelectCompetencia(competencia.id)} style={{ color: 'var(--Red)' }}>
                <FaTrash style={{ marginRight: '.25rem' }} />Remover</span>
            )
          }
        </ColumnIcons>
      </CompetenciaMenu>
      <Flex flexDirection={'column'} width='100%' justifyContent={'space-between'} px={'.5rem'}>

        <CompetenciaContent>
          {
            competencia.questoes.filter((e: iQuestao) => {
              if (selecionadas?.questoesId.find(r => r === e.id) !== undefined) {
                return e
              }
              return null
            }).map((e: iQuestao, i) => {
              if (e.tipo !== 1) {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelectQuestao(competencia.id, e.id)}>
                      <FaCheckCircle size={20} color='var(--Azul)' />
                      <span>{e.enunciado}</span>
                    </StyledQuestion>
                    <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'} >
                    <ElementoTooltipCmp
                      background={'var(--Azul)'} label={'Duplicar questão'}
                      >
                      <div>
                        <FaCopy size={20} color='var(--Azul)' onClick={() => onModalQuestao(competencia.id, e.id, true) }/>
                      </div>
                      </ElementoTooltipCmp>
                      {
                        (!e.doSistema) && (
                          <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      background={'var(--Rosa)'} label={'Editar questão'}
                      >
                        <div>
                            <FaEdit size={20} color='var(--Rosa)' onClick={() => onModalQuestao(competencia.id, e.id)} />
                            </div>
                        </ElementoTooltipCmp>
                        <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>

                        <ElementoTooltipCmp
                      background={'var(--Red)'} label={'Excluir questão'}
                      >
                        <div>
                            <FaTrash size={20} color='var(--Red)' onClick={() => onOpenModalDelete(competencia.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            </Flex>
                          </Flex>

                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              } else {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelectQuestao(competencia.id, e.id)}>
                      <FaCheckCircle size={20} color='var(--Azul)' />
                      <span>{e.enunciado}</span>
                    </StyledQuestion>

                    <Flex margin={'0rem 1rem'} alignItems={'center'}>
                      <SwitchCmp value={ctxFormulario.obrigatorias?.find(q => q === e.id) !== undefined} OnChange={() => SetQuestaoObrigatoria(e.id)} legendaDireita={'Obrigatória'}/>
                    </Flex>

                    <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Azul)'} label={'Duplicar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                      <FaCopy size={20} color='var(--Azul)' onClick={() => onModalQuestao(competencia.id, e.id, true) }/>
                      </div>
                      </ElementoTooltipCmp>
                      {
                        (!e.doSistema) && (
                          <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Roxo)'} label={'Editar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaEdit size={20} color='var(--Roxo)' onClick={() => onModalQuestao(competencia.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                            <ElementoTooltipCmp
                      background={'var(--Red)'} label={'Excluir questão'}
                      >
                        <div>
                            <FaTrash size={20} color='var(--Red)' onClick={() => onOpenModalDelete(competencia.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                          </Flex>
                          </Flex>
                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              }
            })
          }

          {
            (competencia.questoes.filter((e: iQuestao) => {
              if (selecionadas?.questoesId.find(r => r === e.id) !== undefined) {
                return e
              }
              return null
            }).length < competencia.questoes.length) && (
              <CollapseText onClick={() => { setisOpen(!isOpen) }}>
                {
                  !isOpen
                    ? <FaArrowCircleDown />
                    : <FaArrowCircleRight />
                }
                <span>Clique aqui para recolher as questões não selecionadas</span>
              </CollapseText>
            )
          }

          <SectionQuestoes active={isOpen}>
            {
              competencia.questoes.filter((e: iQuestao) => {
                if (selecionadas?.questoesId.find(r => r === e.id) === undefined) {
                  return e
                }
                return null
              }).map((e: iQuestao, i) => {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelectQuestao(competencia.id, e.id)}>
                      <FaCheckCircle size={20} color='var(--c6)' />
                      <span>{e.enunciado}</span>
                    </StyledQuestion>
                    <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      background={'var(--Azul)'} label={'Duplicar questão'}
                      >
                        <div>
                      <FaCopy color='var(--Azul)' onClick={() => onModalQuestao(competencia.id, e.id, true) }/>
                      </div>
                      </ElementoTooltipCmp>
                      {
                        (!e.doSistema) && (
                          <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      background={'var(--Rosa)'} label={'Editar questão'}
                      >
                        <div>
                            <FaEdit color='var(--Rosa)' onClick={() => onModalQuestao(competencia.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                            <ElementoTooltipCmp
                      background={'var(--Red)'} label={'Excluir questão'}
                      >
                        <div>
                            <FaTrash color='var(--Red)' onClick={() => onOpenModalDelete(competencia.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            </Flex>
                          </Flex>

                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              })
            }
          </SectionQuestoes>
        </CompetenciaContent>
        <Flex justifyContent={'end'} alignItems={'end'} margin={'0rem 0rem .5rem 0rem'}>
          <ButtonCmp
            onClick={() => onModalQuestao(competencia.id)} leftIcon={<FaPlus size={12}/>} VarColor='Green2'>Criar questão</ButtonCmp>
        </Flex>
      </Flex>

    </Container>
  )
}
