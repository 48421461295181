import { FaFolderOpen, FaPlus, FaCheck } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Competencia } from './Components/Competencia'
import { DropMenu, HeadBox } from './styles'
import { CardCompetencia } from './Components/CardCompetencia'
import React, { useEffect, useState } from 'react'
import { ModalPreVizualizarQuestao } from './Components/ModalPreVizualizarQuestoes'
import { ModalQuestao } from './Components/ModalQuestao'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { iCompetencia } from '../../../interfaces'
import { ModalCriarCompetencia } from './Components/ModalCriarCompetencia'
import { ModalTodasCompetencias } from './Components/ModalTodasCompetencias'
import { useFormulario } from '../../../contexts/FormularioContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flex, Grid, Text } from '@chakra-ui/react'
import { ModalDelete } from '../../../components/ModalDelete'
import { appApi } from '../../../services/appApi'
import { SearchCmp } from '../../../components/SearchCmp'
import { InfoTooltipCmp } from '../../../components/InfoTooltipCmp'
import { SwitchCmp } from '../../../components/SwitchCmp'

export const FormularioPesquisa: React.FC = () => {
  const path = useLocation().pathname
  const nav = useNavigate()

  const { loadForm, SelectCompetencia, ObterCompetencias, PatchJustificativa, ctxFormulario, ctxCompetencias } = useFormulario()
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [PreVisualizarisOpen, setPreVisualizarisOpen] = useState(false)
  const [QuestaoIsOpen, setQuestaoIsOpen] = useState(false)
  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])

  const [ModalCompetenciaisOpen, setModalCompetenciaisOpen] = useState(false)
  const [CriarCompetisOpen, setCriarCompetisOpen] = useState(false)

  const [DeleteQuestaoIsOpen, setDeleteQuestaoIsOpen] = useState(false)
  const [DeleteCompetenciaIsOpen, setDeleteCompetenciaIsOpen] = useState(false)

  const [CompetenciaId, setCompetenciaId] = useState<string>()
  const [QuestaoId, setQuestaoId] = useState<string>()
  const [Duplicar, setDuplicar] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)

  function DeletarQuestao(): void {
    setIsLoading(true)
    appApi.delete(`Competencia/${CompetenciaId ?? ''}/questao/${QuestaoId ?? ''}`)
      .then(ObterCompetencias)
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function DeletarCompetencia(): void {
    setIsLoading(true)
    appApi.delete(`Competencia/${CompetenciaId ?? ''}`)
      .then(ObterCompetencias)
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function onOpenModalEditCompetencia(id: string): void {
    setCompetenciaId(id)
    setCriarCompetisOpen(true)
  }

  function onCloseModalCreateCompetencia(): void {
    setCriarCompetisOpen(false)
    setCompetenciaId(undefined)
  }

  function onOpenModalQuestao(competenciaId: string, questaoId?: string, duplicar?: boolean): void {
    setQuestaoIsOpen(true)
    setCompetenciaId(competenciaId)
    if (questaoId) {
      setQuestaoId(questaoId)
    }

    if (duplicar) {
      setDuplicar(true)
    }
  }

  function onCloseModalQuestao(): void {
    setQuestaoIsOpen(false)
    setCompetenciaId(undefined)
    setQuestaoId(undefined)
    setDuplicar(false)
  }

  function onOpenModalDelete(competenciaId: string, questaoId?: string): void {
    setDeleteQuestaoIsOpen(true)
    setCompetenciaId(competenciaId)
    setQuestaoId(questaoId)
  }

  function onOpendModalDeleteCompetencia(competenciaId: string): void {
    setDeleteCompetenciaIsOpen(true)
    setCompetenciaId(competenciaId)
  }

  function onCloseModalDelete(): void {
    setDeleteQuestaoIsOpen(false)
    setCompetenciaId(undefined)
    setQuestaoId(undefined)
    setDeleteCompetenciaIsOpen(false)
  }

  useEffect(() => {
    ObterCompetencias()
    loadForm()
  }, [])

  useEffect(() => {
    if (ctxFormulario.status === 2) {
      nav(`${path.replace('Formulario', 'VisualizarFormulario')}`)
    }
  }, [ctxFormulario])

  useEffect(() => {
    setCompetencias(ctxCompetencias.sort((a, b) => { return (b.quantidadeUsos ?? 0) - (a.quantidadeUsos ?? 0) }))
  }, [ctxCompetencias])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={DeleteCompetenciaIsOpen}
        message='Deseja realmente excluir esta competência?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarCompetencia}
      />

      <ModalDelete
        isOpen={DeleteQuestaoIsOpen}
        message='Deseja realmente excluir esta questão?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarQuestao}
      />

      {
        (ModalCompetenciaisOpen) && (
          <ModalTodasCompetencias
            isOpen={ModalCompetenciaisOpen}
            OnCloseModal={() => setModalCompetenciaisOpen(false)}
          />
        )
      }

      <ModalPreVizualizarQuestao
        CompetenciaId={CompetenciaId ?? ''}
        OnCloseModal={() => setPreVisualizarisOpen(false)}
        isOpen={PreVisualizarisOpen}
      />

      <ModalQuestao
        competenciaId={CompetenciaId ?? ''}
        isOpen={QuestaoIsOpen}
        OnCloseModal={onCloseModalQuestao}
        questaoId={QuestaoId}
        duplicar={Duplicar}
      />

      <ModalCriarCompetencia
        OnCloseModal={onCloseModalCreateCompetencia}
        isOpen={CriarCompetisOpen}
        CompetenciaId={CompetenciaId}
      />

      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <Flex flexDir={'column'}>
          <Flex alignItems={'center'} flexWrap={'wrap'}>
            <h2>Selecione as competências</h2>
          </Flex>
          <span style={{ fontWeight: 'bold', fontSize: '.813rem', color: 'var(--c7)' }}>Escolha as competências que deseja e selecione as questões relacionadas a elas. Você também pode criar competências personalizadas.</span>
        </Flex>
        <div>
          <SearchCmp
            width={'22rem'}
            OnChange={(e) => setTxtPesquisa(e)}
            placeholder='Buscar Competências'
            EnterAction={() => {}}
          />
          <DropMenu display={TxtPesquisa.length > 0 ? '' : 'none'}>
            {
              ctxCompetencias.filter(e => !e.discursiva).filter((e: iCompetencia) => {
                if (TxtPesquisa === '') {
                  return e
                } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLocaleLowerCase())) {
                  return e
                }
                return null
              }).map((e: iCompetencia, i) => {
                if (ctxFormulario.selecoes.find(r => r.competenciaId === e.id) === undefined) {
                  return (
                    <li key={i}>
                      <small>{e.nome}</small>
                      <button onClick={() => SelectCompetencia(e.id)}><FaPlus /></button>
                    </li>
                  )
                }
                return (
                  <li key={i}>
                    <small>{e.nome}</small>
                    <div>
                      <FaCheck />
                    </div>
                  </li>
                )
              })
            }
          </DropMenu>
        </div>
      </Flex>

      <WhiteContainer margin='.75rem 0rem .75rem 0rem'>
        <HeadBox>
          <h4 style={{ fontWeight: 700 }}>Competências mais utilizadas em sua organização</h4>
          <Flex flexWrap={'wrap'} gap={'.75rem'}>
            <ButtonCmp
              onClick={() => setModalCompetenciaisOpen(true)} VarColor={'Rosa'} leftIcon={<FaFolderOpen/>}>Todas as competências</ButtonCmp>
            <ButtonCmp
              onClick={() => setCriarCompetisOpen(true)} VarColor='Green2' leftIcon={<FaPlus
              />}>Criar nova competência</ButtonCmp>
          </Flex>
        </HeadBox>
        <Grid templateColumns={'repeat(7,1fr)'} gap={'.5rem'}>
          {
            Competencias.sort((a, b) => { return (b.quantidadeUsos ?? 0) - (a.quantidadeUsos ?? 0) }).filter(e => !e.discursiva).map((e: iCompetencia, i) => {
              if (i > 6) return null
              return (
                <CardCompetencia
                  key={i}
                  Competencia={e}
                  Selecionado={ctxFormulario.selecoes.find(c => c.competenciaId === e.id) !== undefined}
                  Edit={() => { setCompetenciaId(e.id); setCriarCompetisOpen(true) }}
                  onDeleteCompetencia={onOpendModalDeleteCompetencia}
                  onClickPreVisualizar={() => {
                    setCompetenciaId(e.id)
                    setPreVisualizarisOpen(true)
                  }}
                />
              )
            })
          }
        </Grid>
      </WhiteContainer>
      {
        (ctxFormulario.selecoes.filter(e => ctxCompetencias.some(r => !r.discursiva && r.id === e.competenciaId)).length > 0) && (
          <>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <h3>Questões selecionadas</h3>
              <Flex gap={'.5rem'} alignItems={'center'}>
                <Flex gap={'.325rem'} alignItems={'center'}>
                  <Text fontSize={'.875rem'}>Comentários nas questões</Text>
                  <InfoTooltipCmp VarColor='Green2' label='Ao acionar os comentários nas questões, um campo de justificativa aparecerá abaixo de cada questão, assim o avaliador pode justificar a resposta sempre que quiser. A justificativa nunca é obrigatória.'/>
                </Flex>
                <SwitchCmp value={ctxFormulario.justificativa} OnChange={() => PatchJustificativa(ctxFormulario.id)}/>
              </Flex>
            </Flex>
            <WhiteContainer margin='.75rem 0'>
              <Flex flexDir={'column'} gap={'.5rem'}>
                {
                  ctxCompetencias.filter(e => !e.discursiva && ctxFormulario.selecoes.find(r => r.competenciaId === e.id))
                    .map((e: iCompetencia, i) => {
                      return (
                      <Competencia
                        key={i}
                        competencia={e}
                        onModalQuestao={onOpenModalQuestao}
                        onOpenModalDelete={onOpenModalDelete}
                        Edit={() => onOpenModalEditCompetencia(e.id)}
                      />
                      )
                    })
                }
              </Flex>
            </WhiteContainer>
          </>
        )
      }

      <>
        <h3>Questões discursivas</h3>
        <Flex mt={'.5rem'}>
          {
            ctxCompetencias.filter(e => e.discursiva).map((e: iCompetencia, i) => {
              return (
                <Competencia
                  key={i}
                  competencia={e}
                  onOpenModalDelete={onOpenModalDelete}
                  onModalQuestao={onOpenModalQuestao}
                  Edit={() => { }}
                />
              )
            })
          }
        </Flex>
      </>

      {!ctxFormulario.semFluxoPesq && <Flex justifyContent={'end'} mt={'1rem'}gap={'.5rem'}>
        <ButtonCmp VarColor='c6'onClick={() => nav(-1)}>Voltar</ButtonCmp>
          <ButtonCmp
            VarColor='Green2'
            isDisabled={!ctxFormulario.selecoes.filter(r => r.competenciaId !== ctxCompetencias.find(t => t.discursiva)?.id).some(r => r.questoesId.length > 0)}
            onClick={() => nav(`${path.replace('Formulario', 'VisualizarFormulario')}`)}
          >Visualizar formulário</ButtonCmp>
        </Flex>
      }
      {ctxFormulario.semFluxoPesq && <Flex justifyContent={'end'} mt={'1rem'}gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>Voltar para gestão de formulários</ButtonCmp>
        </Flex>
      }
    </Body>
  )
}
