/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { ItemGrafico } from '../../../../interfaces'

interface iChartProps {
  id: string
  dados: ItemGrafico[]
  setGrafico: (blob: string) => void
  legendas?: string[]
  questoesAfetam?: string[]
  alavancagem?: boolean
}

export const BarChartPDF: React.FC<iChartProps> = ({ id, dados, setGrafico, alavancagem, legendas, questoesAfetam }) => {
  function GetSeries(): any {
    const resp: any[] = []
    dados.forEach(e => {
      if (alavancagem && e.valorX) {
        resp.push(e.valorX)
      } else {
        resp.push(e.valor)
      }
    })

    return [{ name: alavancagem ? 'Alavancagem' : 'Média', data: resp }]
  }
  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: questoesAfetam ? ['#da84d5', 'rgb(123, 229, 194)'] : alavancagem ? ['rgb(209, 255, 117)'] : ['rgba(0, 180, 90, 0.9)'],
      series: GetSeries(),
      chart: {
        type: 'bar',
        height: 450,
        width: 900,
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          },
          distributed: true
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: alavancagem ? ['Alavancagem'] : legendas ?? ['Média'],
        position: 'top',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        },
        markers: {
          size: 11,
          offsetX: -3,
          background: 'rgba(0, 180, 90, 0.9)'
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: alavancagem ? dados.some(e => e.valorX && e.valorX > 1.4) ? 1.6 : dados.some(e => e.valorX && e.valorX > 1) ? 1.4 : dados.some(e => e.valorX && e.valorX > 0.6) ? 1 : 0.6 : 10,
        tickAmount: 10,
        show: true
      },
      xaxis: {
        categories: dados.map(e => e.titulo),
        labels: {
          rotate: 0,
          hideOverlappingLabels: false,
          trim: true
        }
      },
      fill: {
        opacity: 1,
        colors: questoesAfetam ? dados.map(e => questoesAfetam.includes(e.perguntaId) ? 'rgb(123, 229, 194)' : '#da84d5') : alavancagem ? ['rgb(209, 255, 117)'] : ['rgba(0, 180, 90, 0.9)']
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    setGrafico(dataURL.imgURI)
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
    <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
      <div id={id}></div>
    </Flex>
  )
}
