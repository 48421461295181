/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { ItemGrafico } from '../../../../interfaces'

interface iChartProps {
  id: string
  dados: ItemGrafico[]
  setGrafico: (blob: string) => void
}

export const PieChartPDF: React.FC<iChartProps> = ({ id, dados, setGrafico }) => {
  function ObterLabel(): string[] {
    const dataChart: any[] = []

    if (dados != null) {
      dados.forEach(e => {
        const dd = e.titulo.split('/')
        if (dd.length > 1) {
          e.titulo = ''
          dd.forEach(item => {
            e.titulo += item + '\n'
          })
        }
        dataChart.push(e.titulo)
      })
    }

    return dataChart
  }

  async function GerarGrafico(): Promise<void> {
    const options = {
      series: dados.map(e => e.valor),
      labels: ObterLabel(),
      colors: ['rgba(0, 153, 255, 0.9)', 'rgba(255, 153, 0, 0.9)', 'rgba(255, 80, 80, 0.9)', 'rgba(0, 180, 90, 0.9)', 'rgba(255, 204, 0, 0.9)',
        'rgba(0, 255, 153, 0.9)', 'rgba(102, 255, 255, 0.9)', 'rgba(0, 153, 153, 0.9)', 'rgba(204, 255, 102, 0.9)', 'rgba(155, 179, 255, 0.9)',
        'rgba(255, 135, 135, 0.9)', 'rgba(255, 255, 51, 0.9)', 'rgba(178, 101, 255, 0.9)', 'rgba(255, 228, 201, 0.9)', 'rgba(255, 255, 153, 0.9)', 'rgba(0, 153, 255, 0.9)', 'rgba(255, 153, 0, 0.9)', 'rgba(255, 80, 80, 0.9)', 'rgba(0, 180, 90, 0.9)', 'rgba(255, 204, 0, 0.9)',
        'rgba(0, 255, 153, 0.9)', 'rgba(102, 255, 255, 0.9)', 'rgba(0, 153, 153, 0.9)', 'rgba(204, 255, 102, 0.9)', 'rgba(155, 179, 255, 0.9)',
        'rgba(255, 135, 135, 0.9)', 'rgba(255, 255, 51, 0.9)', 'rgba(178, 101, 255, 0.9)', 'rgba(255, 228, 201, 0.9)', 'rgba(255, 255, 153, 0.9)'],
      chart: {
        type: 'pie',
        height: 450,
        width: 450,
        animations: {
          enabled: false
        }
      },
      legend: {
        enabled: true
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: any) {
            return val + '%'
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + '%'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false
            }
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    setGrafico(dataURL.imgURI)
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
    <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
      <div id={id}></div>
    </Flex>
  )
}
