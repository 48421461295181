import { FormControl, FormLabel, Input, InputProps, Text } from '@chakra-ui/react'

interface iFormProps extends InputProps {
  label?: string
  OnChange: (e: string) => void
  errorMessage?: string
  error?: boolean
  readOnly?: boolean
  themeWhite?: boolean
  extraLabel?: any
}

export const InputCmp: React.FC<iFormProps> = ({ label, extraLabel, error, themeWhite, readOnly, errorMessage, OnChange, width, type, w, pl, ...rest }) => {
  return (
    <FormControl width={width ?? w ?? '100%'}>
      {label && <FormLabel pos={'relative'} mr={'0'} color={themeWhite ? 'var(--c1)' : 'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>{label} {extraLabel}</FormLabel>}
      <Input readOnly={readOnly} pl={pl} {...rest} bg={themeWhite ? 'var(--c1)' : ''} size={'xs'} fontSize={'.875rem'} height={'1.925rem'} type={type ?? 'text'} width={width} onChange={(e) => OnChange(e.target.value)} _focus={{ boxShadow: '0 0 0 1px #3182ce !important' }} borderColor='var(--Gray4)'/>
      {
        (error && errorMessage) && (
          <Text marginTop={'.125rem'} color={'var(--Red)'} pl={'.125rem'} fontSize={'.75rem'}>{errorMessage}</Text>
        )
      }
    </FormControl>
  )
}
