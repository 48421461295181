/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Flex, Menu, Text, MenuList } from '@chakra-ui/react'
import { BsFillPhoneFill } from 'react-icons/bs'
import { FaChevronDown, FaEye, FaFileCsv, FaTrash, FaCopy, FaRegStopCircle, FaEyeSlash, FaPenSquare } from 'react-icons/fa'
import { MenuButtonCmp } from '../../../../../../components/MenuButtonCmp'
import { StyledMenuItem } from '../../../../../../components/NavBar/Components/StyledMenuItem'
import { iCardPesquisaEngajamento, iPermissoesGestaoEngajamento } from '../../../../../../interfaces'
import { GoGraph } from 'react-icons/go'
import { ObterToken } from '../../../../../../Utils/Helper'
import { ColumnText, PreViewParticipante, GridContainer } from './styles'
import { parseJwt } from '../../../../../../services/token'
import { useNavigate } from 'react-router-dom'
import { IoMail } from 'react-icons/io5'
import { urlApi } from '../../../../../../services/appApi'

interface CardProps {
  pesquisa: iCardPesquisaEngajamento
  permissoes: iPermissoesGestaoEngajamento
  onDelete: () => void
  onReabrir: () => void
}

export const CardPesquisa: React.FC<CardProps> = ({ pesquisa, onDelete, onReabrir, permissoes }) => {
  const nav = useNavigate()
  function ObterCorStatus(): string {
    switch (pesquisa.statusDaPesquisa) {
      case 'encerrada':
        return 'var(--Green2)'
      case 'aguardando Análise':
        return 'var(--Orange)'
      case 'preenchimento':
        return 'var(--Red)'
      case 'elaboração':
      case 'Aguardando início':
      case 'selecionando participantes':
        return 'var(--Yellow)'
      default:
        return ''
    }
  }

  return (
        <PreViewParticipante color={ObterCorStatus()}>
            <Box w={'.3rem'} h={'.3rem'} bg={ObterCorStatus()} borderRadius={'50%'} pos={'absolute'} top={'10px'} left={'10px'}></Box>
            <GridContainer>
                <ColumnText>
                    <span><strong>{pesquisa.descricao}</strong></span>
                    <h4 style={{ color: ObterCorStatus(), fontSize: '.925rem', marginTop: '.2rem' }}><strong>Status: {pesquisa.statusDaPesquisa}</strong></h4>
                </ColumnText>

                <ColumnText>
                    <h4>Data de início</h4>
                    <span>{pesquisa.dataInicio}</span>
                </ColumnText>

                <ColumnText>
                    <h4>Data de fim</h4>
                    <span>{pesquisa.dataFim}</span>
                </ColumnText>

                <ColumnText>
                    <h4>Adesão</h4>
                    <span>{pesquisa.adesao}</span>
                </ColumnText>

                <Flex flexDir={'column'} justifyContent={'center'}>
                    <Text fontSize={'.875rem'}>Convites: <strong style={{ color: 'var(--Red)' }}>{pesquisa.convites}</strong></Text>
                    <Text fontSize={'.875rem'}>Respostas: <strong style={{ color: 'var(--Green2)' }}>{pesquisa.respostas}</strong></Text>
                </Flex>
            </GridContainer>
            <Flex alignItems={'center'} marginRight={'1rem'}>
                <Menu>
                    <MenuButtonCmp
                        Icon={<FaChevronDown size={8} style={{ marginLeft: '-4px' }}/>}
                        VarColor='Green2'
                    >Opções
                    </MenuButtonCmp>
                    <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
                      <StyledMenuItem onClick={() => nav(`/Engajamento/Pec/VisualizarFormulario/${pesquisa.id}`)}>
                          <FaEye style={{ marginRight: '.375rem' }} size={'14'}/>
                          Visualizar formulário
                      </StyledMenuItem>
                      {pesquisa.pesqusiaManual && (
                          <>
                              <StyledMenuItem onClick={onReabrir}>
                                  <FaEye style={{ marginRight: '.375rem' }} size={'14'}/>
                                  Cadastro manual
                              </StyledMenuItem>
                              <StyledMenuItem onClick={() => nav(`/Engajamento/Pec/Resultado/${pesquisa.id}`)}>
                                  <GoGraph style={{ marginRight: '.375rem' }} size={'14'}/>
                                  Relatório
                              </StyledMenuItem>
                          </>
                      )}

                      {!pesquisa.formularioAprovado && pesquisa.ativo && parseJwt().adm === 'True' && (
                          <StyledMenuItem onClick={() => nav(`/Engajamento/PEC/Formulario/${pesquisa.id}`)}>
                              <FaPenSquare style={{ marginRight: '.375rem' }} size={'14'}/>
                              Editar pesquisa
                          </StyledMenuItem>
                      )}

                      {!pesquisa.encerrada && pesquisa.formularioAprovado && pesquisa.ativo && parseJwt().adm === 'True' && (
                          <StyledMenuItem onClick={() => nav(`/Engajamento/PEC/Convites/${pesquisa.id}`)}>
                              <IoMail style={{ marginRight: '.375rem' }} size={'14'}/>
                              Gestão de convites
                          </StyledMenuItem>
                      )}

                      {pesquisa.encerrada && !pesquisa.pesqusiaManual && (
                          <>
                              {pesquisa.plano === 'Explorer'
                                ? (
                                  <>
                                        <StyledMenuItem onClick={() => nav(`/Engajamento/Pec/Resultado/${pesquisa.id}`)}>
                                          <GoGraph style={{ marginRight: '.375rem' }} size={'14'}/>
                                          Relatório
                                      </StyledMenuItem>
                                      {!pesquisa.liberarResultadosApp && pesquisa.ativo && parseJwt().adm === 'True'
                                        ? (
                                          <StyledMenuItem onClick={onReabrir}>
                                              <BsFillPhoneFill style={{ marginRight: '.375rem' }} size={'14'}/>
                                              Liberar resultados aplicativo
                                          </StyledMenuItem>
                                          )
                                        : (
                                            pesquisa.ativo && parseJwt().adm === 'True' && (
                                              <StyledMenuItem onClick={onReabrir}>
                                                  <FaEyeSlash style={{ marginRight: '.375rem' }} size={'14'}/>
                                                  Ocultar resultados aplicativo
                                              </StyledMenuItem>
                                            )
                                          )}
                                  </>
                                  )
                                : (
                                  <>
                                      {pesquisa.revisadaPro && <>
                                          <StyledMenuItem onClick={() => nav(`/Engajamento/Pec/Resultado/${pesquisa.id}`)}>
                                            <GoGraph style={{ marginRight: '.375rem' }} size={'14'}/>
                                            Relatório
                                          </StyledMenuItem>
                                          {!pesquisa.liberarResultadosApp && pesquisa.ativo && parseJwt().adm === 'True' && (
                                          <StyledMenuItem onClick={onReabrir}>
                                              <BsFillPhoneFill style={{ marginRight: '.375rem' }} size={'14'}/>
                                              Liberar resultados aplicativo
                                          </StyledMenuItem>)}
                                        </>
                                      }
                                  </>
                                  )}
                          </>
                      )}

                      {permissoes.csv && parseJwt().adm === 'True' && (
                          <StyledMenuItem onClick={onReabrir}>
                              <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                              CSV
                          </StyledMenuItem>
                      )}

                      {!pesquisa.encerrada && pesquisa.respostas > 0 && pesquisa.dataFim && pesquisa.ativo && parseJwt().adm === 'True' && (
                          <>
                              {Date.now() > new Date(pesquisa.dataFim).getTime() && (
                                pesquisa.plano === 'Explorer'
                                  ? (
                                      <StyledMenuItem onClick={onReabrir}>
                                          <FaRegStopCircle style={{ marginRight: '.375rem' }} size={'14'}/>
                                          Encerrar pesquisa
                                      </StyledMenuItem>
                                    )
                                  : (
                                      <StyledMenuItem onClick={onReabrir}>
                                          <FaRegStopCircle style={{ marginRight: '.375rem' }} size={'14'}/>
                                          Enviar pesquisa para análise
                                      </StyledMenuItem>
                                    )
                              )}
                          </>
                      )}
                      {permissoes.csv && !pesquisa.encerrada && pesquisa.ativo && parseJwt().adm === 'True' && (
                          <StyledMenuItem onClick={onReabrir}>
                              <FaEye style={{ marginRight: '.375rem' }} size={'14'}/>
                              Maroto
                          </StyledMenuItem>
                      )}

                      {pesquisa.formularioAprovado && parseJwt().adm === 'True' && (
                          <StyledMenuItem onClick={onReabrir}>
                              <FaCopy style={{ marginRight: '.375rem' }} size={'14'}/>
                              Duplicar pesquisa
                          </StyledMenuItem>
                      )}

                      {parseJwt().adm === 'True' && (
                          <>
                              <StyledMenuItem onClick={() => { window.location.href = `${urlApi}/FormularioPec/${pesquisa.id}/Csv?token=${ObterToken()}` }}>
                                  <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                                  Exportar CSV formulário
                              </StyledMenuItem>
                              {pesquisa.respostas > 0 && pesquisa.encerrada && (
                                      <>
                                          {pesquisa.temDiscursiva && (
                                            pesquisa.temRespostaDiscursiva
                                              ? (
                                                  <StyledMenuItem onClick={onDelete}>
                                                      <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                                                      Exportar CSV Comentários
                                                  </StyledMenuItem>
                                                )
                                              : (
                                                  <StyledMenuItem onClick={onDelete} className="text-danger" style={{ cursor: 'not-allowed' }} title="Ninguém comentou nesta pesquisa">
                                                      <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                                                      Exportar CSV Comentários
                                                  </StyledMenuItem>
                                                )
                                          )}
                                          {permissoes.liberarBase && (
                                              <StyledMenuItem onClick={onDelete}>
                                                  <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                                                  Exportar CSV Base
                                              </StyledMenuItem>
                                          )}
                                      </>
                              )}
                          </>
                      )}

                      {((permissoes.apagar && parseJwt().eid === permissoes.demoId) || (permissoes.csv && parseJwt().adm === 'True')) && pesquisa.encerrada && pesquisa.ativo && (
                          <StyledMenuItem onClick={onDelete}>
                              <FaTrash style={{ marginRight: '.375rem' }} size={'14'}/>
                              Excluir pesquisa
                          </StyledMenuItem>
                      )}

                      {!pesquisa.encerrada && pesquisa.ativo && parseJwt().adm === 'True' && (
                          <StyledMenuItem onClick={onDelete}>
                              <FaTrash style={{ marginRight: '.375rem' }} size={'14'}/>
                              Excluir pesquisa
                          </StyledMenuItem>
                      )}

                      {pesquisa.encerrada && parseJwt().adm === 'True' && pesquisa.pesqusiaManual && (
                          <StyledMenuItem onClick={onDelete}>
                              <FaTrash style={{ marginRight: '.375rem' }} size={'14'}/>
                              Excluir pesquisa
                          </StyledMenuItem>
                      )}
                  </MenuList>
                </Menu>
            </Flex>
        </PreViewParticipante>
  )
}
