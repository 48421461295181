import { FaMapMarkerAlt, FaAngleDown, FaAngleUp, FaUserCheck, FaCheck } from 'react-icons/fa'
import { BackgroundCollapse, CardBody, ColumnText, HeaderSection, PreViewParticipante, SectionAssociados, SectionBody } from './styles'
import { useState } from 'react'
import { iAvaliado, iCardAvaliador } from '../../../../../interfaces'
import { CardAssociado } from '../CardAssociado'
import { Avatar, Flex } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { IoMdCloseCircleOutline } from 'react-icons/io'

interface iCardProps {
  enviado: boolean
  ciclica: boolean
  Avaliacao: iAvaliado
  onDeleteAvaliacao: (avaliacaoId: string) => void
  onEdit: (avaliacaoId: string, avaliador: iCardAvaliador) => void
  onOpenModal: (tipo: number, avaliacaoId: string) => void
  onReenviarAvaliador: (avaliacaoId: string, avaliador?: iCardAvaliador) => void
}

export const CardParticipante: React.FC<iCardProps> = ({ Avaliacao, enviado, ciclica, onDeleteAvaliacao, onOpenModal, onReenviarAvaliador, onEdit }) => {
  const [isOpen, setisOpen] = useState(false)
  const hasAlto = Avaliacao.participantes.find(e => e === 0) !== undefined
  function ObterTotal(): number {
    let i: number = 0
    if (Avaliacao.pares) {
      i += Avaliacao.pares !== undefined ? Avaliacao.pares.length : 0
    }

    if (Avaliacao.lider) {
      i += Avaliacao.lider !== undefined ? Avaliacao.lider.length : 0
    }

    if (Avaliacao.altaLideranca) {
      i += Avaliacao.altaLideranca !== undefined ? Avaliacao.altaLideranca.length : 0
    }

    if (Avaliacao.liderados) {
      i += Avaliacao.liderados !== undefined ? Avaliacao.liderados.length : 0
    }

    if (Avaliacao.outros) {
      i += Avaliacao.outros !== undefined ? Avaliacao.outros.length : 0
    }

    if (hasAlto) {
      i++
    }
    return i
  }
  return (
        <ElementoTooltipCmp label={Avaliacao.encerrada ? 'Avaliação encerrada, para editar clique em reabrir na tela de resultados!' : Avaliacao.interrompida ? 'Avaliação interrompida, não está disponível para edição!' : ''}>
            <BackgroundCollapse className={Avaliacao.encerrada ? 'disabled' : ''} active={isOpen}>
                <PreViewParticipante>
                    <CardBody column={hasAlto ? 3 : 2}>
                        <Flex>
                            <Avatar size={'sm'} src={Avaliacao.avatar} marginRight={'.5rem'} name={Avaliacao.nome} />
                            <ColumnText>
                                <h4 style={{ fontSize: '.875rem', fontWeight: 600 }}>{Avaliacao.nome}</h4>
                                <span>Avaliado</span>
                                {Avaliacao.dataInicio && <span>Inicio: {Avaliacao.dataInicio}</span>}
                            </ColumnText>
                        </Flex>
                        {
                            (hasAlto) && (
                                <Flex alignItems={'center'}>
                                    <FaUserCheck size={25} style={{ marginRight: '.5rem' }} />
                                    <ColumnText>
                                        <h4 style={{ fontSize: '.813rem', lineHeight: '.875rem' }}>Contém</h4>
                                        <h3 style={{ fontSize: '.875rem', lineHeight: '.875rem', fontWeight: 600 }}>Autoavaliação</h3>
                                    </ColumnText>
                                </Flex>
                            )
                        }

                        <Flex alignItems={'center'}>
                            <FaMapMarkerAlt size={26} style={{ marginRight: '.325rem' }} />
                            <ColumnText>
                                <h3 style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 600 }}>{ObterTotal()}</h3>
                                <span>Avaliadores</span>
                            </ColumnText>
                        </Flex>
                    </CardBody>
                    <Flex alignItems={'center'} gap={'.5rem'}>
                        <Flex alignItems={'center'}>
                            <ButtonCmp rightIcon={!isOpen ? <FaAngleDown className='IconRigth' /> : <FaAngleUp className='IconRigth' />} VarColor='Green2' onClick={() => setisOpen(!isOpen)}>{!isOpen ? 'Visualizar' : 'Minimizar'}</ButtonCmp>
                        </Flex>
                        <ButtonCmp mr={'.5rem'} VarColor={'Red'} onClick={() => onDeleteAvaliacao(Avaliacao.id)}>
                            <MdDelete />
                        </ButtonCmp>
                    </Flex>
                </PreViewParticipante>
                <Flex flexDir={'column'} padding='.5rem .75rem 0rem .75rem'>
                    {enviado && !ciclica && (
                        <Flex mb={'.5rem'} gap={'.75rem'}>
                            <ButtonCmp isDisabled={Avaliacao.respondida} VarColor='Rosa' onClick={() => onReenviarAvaliador(Avaliacao.id)}>Reenviar autoavaliação</ButtonCmp>
                            <Flex gap={'.325rem'} fontSize={'14px'} color={Avaliacao.respondida ? 'var(--Green2)' : 'var(--Red)'} alignItems={'center'}>{Avaliacao.respondida ? <><FaCheck /> Autoavaliação já respondida</> : <><IoMdCloseCircleOutline/> Autoavaliação ainda não foi respondida</>}</Flex>
                        </Flex>
                    )}
                    <SectionAssociados>
                        <HeaderSection>
                            <h4>Gestor({Avaliacao.lider !== null ? Avaliacao.lider?.length : 0})</h4>
                            <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(1, Avaliacao.id)}>Adicionar</ButtonCmp>
                        </HeaderSection>
                        <SectionBody>
                            {
                                Avaliacao.lider?.map((e: iCardAvaliador, i) => {
                                  return (
                                        <CardAssociado
                                            key={i}
                                            enviado={enviado}
                                            avaliacao={Avaliacao}
                                            avaliador={e}
                                            onEdit={onEdit}
                                            tipo={1}
                                        />
                                  )
                                })
                            }
                        </SectionBody>
                    </SectionAssociados>

                    <SectionAssociados>
                        <HeaderSection>
                            <h4>Pares({Avaliacao.pares !== null ? Avaliacao.pares?.length : 0})</h4>
                            <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(2, Avaliacao.id)}>Adicionar</ButtonCmp>
                        </HeaderSection>
                        <SectionBody>
                            {
                                Avaliacao.pares?.map((e: iCardAvaliador, i) => {
                                  return (
                                        <CardAssociado
                                            key={i}
                                            enviado={enviado}
                                            avaliacao={Avaliacao}
                                            avaliador={e}
                                            onEdit={onEdit}
                                            tipo={2}
                                        />
                                  )
                                })
                            }
                        </SectionBody>
                    </SectionAssociados>

                    <SectionAssociados>
                        <HeaderSection>
                            <h4>Outros({Avaliacao.outros !== null ? Avaliacao.outros?.length : 0})</h4>
                            <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(4, Avaliacao.id)}>Adicionar</ButtonCmp>
                        </HeaderSection>
                        <SectionBody>
                            {
                                Avaliacao.outros?.map((e: iCardAvaliador, i) => {
                                  return (
                                        <CardAssociado
                                            key={i}
                                            enviado={enviado}
                                            avaliacao={Avaliacao}
                                            avaliador={e}
                                            onEdit={onEdit}
                                            tipo={4}
                                        />
                                  )
                                })
                            }
                        </SectionBody>
                    </SectionAssociados>

                    <SectionAssociados>
                        <HeaderSection>
                            <h4>Alta liderança({Avaliacao.altaLideranca !== null ? Avaliacao.altaLideranca?.length : 0})</h4>
                            <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(6, Avaliacao.id)}>Adicionar</ButtonCmp>
                        </HeaderSection>
                        <SectionBody>
                            {
                                Avaliacao.altaLideranca?.map((e: iCardAvaliador, i) => {
                                  return (
                                        <CardAssociado
                                            key={i}
                                            enviado={enviado}
                                            avaliacao={Avaliacao}
                                            avaliador={e}
                                            onEdit={onEdit}
                                            tipo={6}
                                        />
                                  )
                                })
                            }
                        </SectionBody>
                    </SectionAssociados>

                    <SectionAssociados>
                        <HeaderSection>
                            <h4>Liderados({Avaliacao.liderados !== null ? Avaliacao.liderados?.length : 0})</h4>
                            <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(3, Avaliacao.id)}>Adicionar</ButtonCmp>
                        </HeaderSection>
                        <SectionBody>
                            {
                                Avaliacao.liderados?.map((e: iCardAvaliador, i) => {
                                  return (
                                        <CardAssociado
                                            key={i}
                                            enviado={enviado}
                                            avaliacao={Avaliacao}
                                            avaliador={e}
                                            onEdit={onEdit}
                                            tipo={3}
                                        />
                                  )
                                })
                            }
                        </SectionBody>
                    </SectionAssociados>
                </Flex>
            </BackgroundCollapse>
        </ElementoTooltipCmp>
  )
}
