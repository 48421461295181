/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { CreateColaboradorModal } from '../../../../components/ModalCreateColaborador'
import { appApi, urlApi } from '../../../../services/appApi'
import { NavBar } from '../Components/NavBar'
import { EmployeeTable } from './Components/EmployeeTable'
import { IEmployee, iPostColaborador, iPutColaborador, iPutDesligamento } from '../../../../interfaces'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Image, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import { parseJwt } from '../../../../services/token'
import { useAuth } from '../../../../contexts/AuthContext'
import { FaFileCsv, FaPlus } from 'react-icons/fa'
import { PopUpDesligarColaborador } from './Components/PopUpDesligarColaborador'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { obterDataParaCsv, ObterToken } from '../../../../Utils/Helper'
import { ModalCadastroEmMassa } from './Components/ModalCadastroEmMassa'
import { Paginador } from '../../../../components/Paginador'
import LoadingContainer from '../../../../components/LoadingContainer'
import { SearchCmp } from '../../../../components/SearchCmp'
import { ModalRealocarColab } from './Components/ModalRealocarColab'

interface iNavLink {
  to: string
  linkTitle: string
  exact?: boolean
}

export interface iFormRealocar {
  id: string
  dataInicio: string
  mandarPrimeiroAcesso: boolean
}

export const ColaboradoresDesenvolvimento: React.FC = () => {
  const { permitirFuncao } = useAuth()
  // const redirectPage = (url: string): any => { window.location.href = url }
  const toast = useToast()
  const nav = useNavigate()

  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingBaixarCsv, setIsLoadingBaixarCsv] = useState(false)

  const [ModalDesligamentoIsOpen, setModalDesligamentoIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalCadastroEmMassaIsOpen, setModalCadastroEmMassaIsOpen] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(1)
  const [Tamanho, setTamanho] = useState(25)
  const [Ordenacao, setOrdenacao] = useState({ filtro: 1, ascendente: false })
  const [Colaboradores, setColaboradores] = useState<IEmployee[]>([])
  const [EditColaborador, setEditColaborador] = useState<IEmployee>()
  const [IdRealocar, setIdRealocar] = useState('')
  const [ModalRealocarIsOpen, setModalRealocarIsOpen] = useState(false)
  const [Desligados, setDesligados] = useState(false)

  const [Search, setSearch] = useState('')
  const [TextoSemColaborador, setTextoSemColaborador] = useState('Nenhum colaborador encontrado')

  function onOpenModalDesligamento(obj: IEmployee): void {
    setModalDesligamentoIsOpen(true)
    setEditColaborador(obj)
  }

  function onCloseModalDesligamento(): void {
    setModalDesligamentoIsOpen(false)
    setEditColaborador(undefined)
  }

  function ObterRotas(): iNavLink[] {
    const rotas: iNavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }

  function onCreateColaborador(form: iPostColaborador): void {
    appApi.post('Colaborador', form).then(res => {
      toast({
        title: 'Colaborador cadastrado com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      nav(`/Rh/VisualizarPerfil/${res.data as string}`)
    }).catch(({ response }) => {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top-right',
        isClosable: false
      })
    })
  }

  function onUpdateColaborador(form: iPutColaborador): void {
    appApi.put(`Colaborador/${EditColaborador?.id ?? ''}`, form).then(() => {
      toast({
        title: 'Colaborador atualizado com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      setModalCreateIsOpen(false)
      setEditColaborador(undefined)
      getColaboradores(Desligados)
    }).catch(({ response }) => {
      setEditColaborador(undefined)
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top-right',
        isClosable: false
      })
    }
    )
  }

  function onOpenEditModal(obj: IEmployee): void {
    setModalCreateIsOpen(true)
    setEditColaborador(obj)
  }

  function BaixarCsv(): void {
    setIsLoadingBaixarCsv(true)
    appApi.get(`${urlApi}/Colaborador/Csv/?token=${ObterToken()}`, { responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const a = document.createElement('a')
        a.href = url
        a.download = `BaseColaboradores_${obterDataParaCsv()}.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingBaixarCsv(false))
  }

  function getColaboradores(desligados: boolean): void {
    setIsLoading(true)
    appApi.get(`Colaborador/?busca=${Search}&ordenador=${Ordenacao.filtro}&ascendente=${!Ordenacao.ascendente}&tamanho=${Tamanho}&pagina=${Pagina}&desligados=${desligados}`).then(res => {
      if (res.status === 204 && Pagina !== 0) {
        setPagina(0)
      } else {
        const data: IEmployee[] = res.data
        setColaboradores(data)
        if (data.length === 0) setTextoSemColaborador('Não foram encontrado colaboradores com esse filtro de busca')
      }
    }).catch(err => { console.log(err) })
      .finally(() => setIsLoading(false))
  }

  function getQtdColaboradores(desligados: boolean): void {
    appApi.get(`Colaborador/Quantidade/?busca=${Search}&desligados=${desligados}`).then(res => {
      if (res.status === 200) {
        setQtd(res.data)
      }
    }).catch(err => console.log(err))
  }

  function DesligarColaborador(form: iPutDesligamento): void {
    appApi.put(`Colaborador/${EditColaborador?.id ?? ''}/Desligar`, form)
      .then(() => {
        if (form.manterAcesso) {
          nav(`/Rh/VisualizarPerfil/${EditColaborador?.id ?? ''}?criar=true`)
        } else {
          toast({
            title: 'Colaborador desligado com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: false
          })
          onCloseModalDesligamento()
          getColaboradores(false)
        }
      })
      .catch(err => console.log(err))
  }

  function onRealocar(form: iFormRealocar): void {
    appApi.put(`Colaborador/Realocar/${form.id}?dataInicio=${new Date(form.dataInicio).toISOString()}&mandarPrimeiroAcesso=${form.mandarPrimeiroAcesso}`)
      .then(() => {
        window.location.reload()
      })
      .catch(err => console.log(err))
  }

  function onCloseRealocar(): void {
    setModalRealocarIsOpen(false)
    setIdRealocar('')
  }

  function onOpenRealocar(id: string): void {
    setModalRealocarIsOpen(true)
    setIdRealocar(id)
  }

  useEffect(() => {
    getQtdColaboradores(Desligados)
  }, [])

  useEffect(() => {
    getColaboradores(Desligados)
    setSearch('')
  }, [Pagina, Ordenacao, Tamanho])

  return (
    <Body>
      {ModalCadastroEmMassaIsOpen &&
        <ModalCadastroEmMassa
          reload={() => getColaboradores(Desligados)}
          isOpen={ModalCadastroEmMassaIsOpen}
          onRequestClose={() => setModalCadastroEmMassaIsOpen(false)}
        />
      }

      {ModalRealocarIsOpen &&
        <ModalRealocarColab
          idRealocado={IdRealocar}
          onClose={onCloseRealocar}
          onRealocar={(e: iFormRealocar) => onRealocar(e)}
        />
      }

      {ModalDesligamentoIsOpen && (
        <PopUpDesligarColaborador
          onClose={onCloseModalDesligamento}
          onDesligar={DesligarColaborador}
          idDesligado={EditColaborador?.id}
        />
      )}

      {ModalCreateIsOpen &&
        <CreateColaboradorModal
          isOpen={ModalCreateIsOpen}
          onCreateEmployee={onCreateColaborador}
          onRequestClose={() => {
            setModalCreateIsOpen(false)
            setEditColaborador(undefined)
          }}
          onUpdateEmployee={onUpdateColaborador}
          colaborador={EditColaborador}
        />
      }

      <Flex flexDir={'column'} marginTop={'2rem'}>
        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />

        <Flex m={'2rem 0 1rem 0'} justifyContent={'space-between'}>
          <Flex gap={'.5rem'}>
            <SearchCmp
              EnterAction={() => { getColaboradores(Desligados); getQtdColaboradores(Desligados) }}
              OnChange={setSearch}
              placeholder='Busca'
              width='16rem'
            />
            <ButtonCmp onClick={() => { getColaboradores(Desligados); getQtdColaboradores(Desligados) }} VarColor='Green2'>Buscar</ButtonCmp>
          </Flex>
          {parseJwt().adm === 'True' &&
            <Flex justifyContent={'end'} gap={'.5rem'}>
              <ButtonCmp
                onClick={() => setModalCadastroEmMassaIsOpen(true)}
                VarColor='Green2'
                leftIcon={<FaFileCsv />}
              >Cadastrar em massa</ButtonCmp>

              <ButtonCmp
                onClick={BaixarCsv}
                VarColor='Green2'
                leftIcon={<FaFileCsv />}
                isLoading={IsLoadingBaixarCsv}
              >Baixar CSV da base</ButtonCmp>

              <ButtonCmp
                onClick={() => setModalCreateIsOpen(true)}
                VarColor='Rosa'
                leftIcon={<FaPlus/>}
              >Novo colaborador</ButtonCmp>
            </Flex>}
        </Flex>
        <Tabs variant='unstyled'>
          <TabList marginBottom={'.01rem'}>
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }} onClick={() => { getColaboradores(false); setDesligados(false); getQtdColaboradores(false) }}>Colaboradores ativos</Tab>
            {parseJwt().adm === 'True' && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'0 .25rem 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }} onClick={() => { getColaboradores(true); setDesligados(true); getQtdColaboradores(true) }}>Colaboradores desligados</Tab>}
          </TabList>
          <TabPanels boxShadow={'var(--SombraBackground)'} background={'var(--c1)'} padding={'1rem 2rem'}>
            <TabPanel p={'0'}>
              <Paginador
                next={(e) => setPagina(e)}
                prev={(e) => setPagina(e)}
                tamanho={Tamanho}
                hideTop
                setTamanho={(e) => { setTamanho(e); setPagina(0) }}
                paginaAtual={Pagina}
                qtdElementos={Qtd}
              >
              <LoadingContainer loading={IsLoading} linhas={22} my={'1rem'}>
                {
                  (Colaboradores) && (
                        <Box mt={'0'}>
                          <EmployeeTable
                            onDelete={onOpenModalDesligamento}
                            ordenacao={Ordenacao.ascendente}
                            ultimoFiltro={Ordenacao.filtro}
                            onEdit={onOpenEditModal}
                            employeeList={Colaboradores}
                            onRealocar={onOpenRealocar}
                            onOrdenar={(filtro, ascendente) => setOrdenacao({ filtro: filtro, ascendente: ascendente }) }
                          />
                        </Box>
                  )
                  }

                  {
                    (Colaboradores.length === 0) && (
                      <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                        <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                        <h3>{TextoSemColaborador}</h3>
                      </Flex>
                    )
                  }
                </LoadingContainer>
              </Paginador>
            </TabPanel>
            {parseJwt().adm === 'True' && <TabPanel p={'0'}>
              <Paginador
                next={(e) => setPagina(e)}
                prev={(e) => setPagina(e)}
                tamanho={Tamanho}
                hideTop
                setTamanho={(e) => { setTamanho(e); setPagina(0) }}
                paginaAtual={Pagina}
                qtdElementos={Qtd}
              >
              <LoadingContainer loading={IsLoading} linhas={22} my={'1rem'}>
                {
                  (Colaboradores) && (
                        <Box mt={'0'}>
                          <EmployeeTable
                            onDelete={onOpenModalDesligamento}
                            ordenacao={Ordenacao.ascendente}
                            ultimoFiltro={Ordenacao.filtro}
                            onEdit={onOpenEditModal}
                            employeeList={Colaboradores}
                            onRealocar={onOpenRealocar}
                            desligados
                            onOrdenar={(filtro, ascendente) => setOrdenacao({ filtro: filtro, ascendente: ascendente }) }
                          />
                        </Box>
                  )
                  }

                  {
                    (Colaboradores.length === 0) && (
                      <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                        <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                        <h3>{TextoSemColaborador}</h3>
                      </Flex>
                    )
                  }
                </LoadingContainer>
              </Paginador>
            </TabPanel>}
          </TabPanels>
        </Tabs>
      </Flex>
    </Body>
  )
}
