import { Flex, Divider, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaComment, FaFileCsv } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { GroupBy } from '../../../../../../Utils/Helper'
import { IModel } from '../../interfaces'

interface iProps {
  Model: IModel
}

export const TabComentarios: React.FC<iProps> = ({ Model }) => {
  const [GrupoSelecionado, setGrupoSelecionado] = useState('')
  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <SelectCmp title='Grupo de análise' value={GrupoSelecionado} OnChange={setGrupoSelecionado} width={'14rem'}>
          <option value={''}>Todas</option>
          {Model.demografiasDDL.map(e => {
            return (
              <option value={e.itemDemografiaId}>{e.pergunta} - {e.nome}</option>
            )
          })}
        </SelectCmp>
        <ButtonCmp leftIcon={<FaFileCsv/>} isDisabled={!(Model.comentarios.filter(e => {
          if (GrupoSelecionado === '') {
            return e
          } else if (e.itemDemografiaId === GrupoSelecionado) {
            return e
          } else {
            return null
          }
        })?.length > (GrupoSelecionado !== '' ? 5 : 0))} onClick={() => {}} VarColor={'Green2'}>Exportar Csv com comentários</ButtonCmp>
      </Flex>
      {Model.comentarios.filter(e => {
        if (GrupoSelecionado === '') {
          return e
        } else if (e.itemDemografiaId === GrupoSelecionado) {
          return e
        } else {
          return null
        }
      })?.length > (GrupoSelecionado !== '' ? 5 : 0)
        ? <Flex flexDir={'column'} gap={'1.5rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'1rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems='center'>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaComment fontSize={'2rem'} color={'var(--Azul)'}/>
          </Flex>
          <h3>Comentários e perguntas discursivas</h3>
        </Flex>
        {GroupBy(Model.comentarios.filter(e => {
          if (GrupoSelecionado === '') {
            return e
          } else if (e.itemDemografiaId === GrupoSelecionado) {
            return e
          } else {
            return null
          }
        }), (item) => item.pesquisaXQuestoesDemografiaId).toList().map(item => {
          return (
            <Flex border={'1px dashed var(--AzulOpaco)'} flexDir={'column'} p={'1.5rem'} gap={'2rem'}>
              <Flex flexDir={'column'}>
                <Text fontWeight={700} color={'var(--Azul)'}>Questão</Text>
                <Text fontWeight={400}>{item.data[0].pergunta}</Text>
              </Flex>
              <Flex flexDir={'column'} gap={'.75rem'}>
                <Text fontWeight={700} color={'var(--Azul)'}>Respostas: {item.data.length}</Text>
              {item.data.map(e => {
                return (
                  <Flex alignItems={'center'} p={'1rem'} background={'var(--AzulOpacoBg)'} border={'1px solid var(--c3)'}>{e.respostaDiscursiva}</Flex>
                )
              })}
              </Flex>
            </Flex>
          )
        })}
      </Flex>
        : <Flex alignItems={'center'} justifyContent={'center'} fontWeight={700} padding={'3rem'}>
            Não foram realizados comentários nessa pesquisa
        </Flex>}

    </>
  )
}
