/* eslint-disable no-unneeded-ternary */
import { Box, Flex } from '@chakra-ui/react'

interface iEmojiProps{
  icon: any
  texto: string
  onClick?: () => void
  flexDir?: any
  disabled?: boolean
}

export const Emoji: React.FC<iEmojiProps> = ({ icon, texto, disabled, onClick, flexDir }) => {
  return (
        <Flex onClick={onClick} opacity={disabled ? '.6' : '1 !important'} cursor={flexDir ? '' : 'pointer'} flexDirection={flexDir ? flexDir : 'column'} textAlign={'center'} alignItems={flexDir ? 'center' : ''} gap={flexDir ? '.5rem' : ''} _hover={{ opacity: '.75' }} transition={'.2s'}>
            {!flexDir && <img style={{ height: '3rem', marginBottom: '.325rem' }} src={icon} />}
            <Box as={'h4'} fontWeight={flexDir ? '800' : !disabled ? '800' : ''} fontSize={flexDir ? '12px' : '14px'}>{texto}</Box>
            {flexDir && <img style={{ height: '1.75rem', marginBottom: '' }} src={icon} />}
        </Flex>
  )
}
