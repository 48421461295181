import styled, { css } from 'styled-components'

interface iContainerProps{
  column?: boolean
  fontSize?: string
  size: string
}

export const Container = styled.div<iContainerProps>`
display: flex;
justify-content: center;
align-items: center;

span{
    margin-left: .3rem;
    color: var(--${props => props.color});
    font-size: 0.75rem;
    font-weight: 500;
    ${props => props.fontSize && css<iContainerProps>`
         font-size: ${props => props.fontSize};
    `}
}

div{
    background: var(--DegradeAzul);
    padding: .1rem;
    border-radius: 2.563rem;
    display: flex;
}

img{
    object-fit: cover;
    background-color: white;
    border-radius: calc(var(--${props => props.size})/2);
    width: var(--${props => props.size});
    height: var(--${props => props.size});
}

${({ column }) => column && `
    flex-direction: column;
    span{
        margin-top:.5rem;
        margin-left:0rem;
    }
`}
`
