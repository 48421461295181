import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { FaFilePdf, FaChartPie } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { IModel } from '../../interfaces'
import { ComparativoChart } from '../../Components/ComparativoChart'

interface iProps {
  Model: IModel
}

export const TabComparativaDemografia: React.FC<iProps> = ({ Model }) => {
  const [GrupoSelecionado, setGrupoSelecionado] = useState('')
  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'end'} mb={'2rem'}>
        <SelectCmp title='Grupo de análise' value={GrupoSelecionado} OnChange={setGrupoSelecionado} width={'14rem'}>
          <option value={''}>Todas</option>
          {Model.demografiasDDL.map(e => {
            return (
              <option value={e.itemDemografiaId}>{e.pergunta} - {e.nome}</option>
            )
          })}
        </SelectCmp>
        <ButtonCmp leftIcon={<FaFilePdf/>} onClick={() => {}} VarColor={'Green2'}>Relatório PDF</ButtonCmp>
      </Flex>
      {Model.comparativoDemografia.map((e, i) => {
        if (e.length === 0) {
          return null
        }
        return (
          <Flex flexDir={'column'} gap={'.75rem'}>
            <Flex gap={'.75rem'} alignItems='center'>
              <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
              </Flex>
              <h3>{e[0].titulo}</h3>
            </Flex>
            <ComparativoChart
              dadosA={e[0].dados}
              dadosB={e[1].dados}
              labels={e[0].dados.map(a => a.titulo)}
              id={`comparativo-demografia-${i}`}
            />
          </Flex>
        )
      })}

    </>
  )
}
