/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { ItemGrafico } from '../../../../interfaces'

interface iChartProps {
  id: string
  dados: ItemGrafico[]
  setGrafico: (blob: string) => void
}

export const ScatterChartPDF: React.FC<iChartProps> = ({ id, dados, setGrafico }) => {
  const [Series, setSeries] = useState<Array<{name: string, data: number[][]}>>()
  function ObterLabel(): Array<{name: string, data: number[][]}> {
    const dataChart: any[] = []

    if (dados != null) {
      dados.forEach(e => {
        const dd = e.titulo.split('/')
        if (dd.length > 1) {
          e.titulo = ''
          dd.forEach(item => {
            e.titulo += item + '\n'
          })
        }
        const newValor = [e.valorX, e.valor]

        dataChart.push({ name: e.titulo, data: [newValor] })
      })
    }

    return dataChart
  }

  useEffect(() => {
    setSeries(ObterLabel())
  }, [dados])

  async function GerarGrafico(): Promise<void> {
    const options = {
      series: Series,
      colors: ['#FF5050'],
      annotations: {
        points: Series?.map(e => {
          return {
            x: e.data[0][0],
            y: e.data[0][1],
            marker: {
              size: 0
            },
            label: {
              offsetY: -5,
              borderColor: 'transparent',
              style: {
                background: 'transparent',
                fontSize: '14px',
                fontWeight: 700
              },
              text: e.name.split('-')[0]
            }
          }
        })
      },
      chart: {
        height: 400,
        width: 750,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy'
        },
        animations: {
          enabled: false
        }
      },
      xaxis: {
        tickAmount: 8,
        labels: {
          formatter: function(val: string) {
            return parseFloat(val).toFixed(1)
          }
        },
        min: 0,
        max: 1.6
      },
      yaxis: {
        tickAmount: 7
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Perguntas que afetam significativamente o engajamento'],
        position: 'top',
        fontSize: '17px',
        markers: {
          size: 11,
          offsetX: -3
        }
      },
      markers: {
        size: 8
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          formatter: function(value: any, { series, seriesIndex, dataPointIndex, w }: any) {
            return Series ? `${Series[seriesIndex].name}: ( Alavancagem: ${Series[seriesIndex].data[0][0]}, Engajamento: ${Series[seriesIndex].data[0][1]})` : ''
          },
          title: {
            formatter: () => ''
          }
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}-pdf`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    setGrafico(dataURL.imgURI)
  }

  useEffect(() => {
    if (Series) {
      GerarGrafico()
    }
  }, [Series])

  return (
    <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
      <div id={`${id}-pdf`}></div>
    </Flex>
  )
}
