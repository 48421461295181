import { Flex, Img, Divider, Grid, GridItem, Text, Box } from '@chakra-ui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import { FaFilePdf, FaChartPie, FaChartLine, FaChartArea, FaThumbsUp, FaThumbsDown, FaPlus } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { GroupBy } from '../../../../../../Utils/Helper'
import { AnaliseEstatisticaPDF } from '../../Components/AnaliseEstatisticaPdf'
import { BarChart } from '../../Components/BarChart'
import { BarChartPDF } from '../../Components/ResultadoPDF/Components/BarChartPdf'
import { ScatterChartPDF } from '../../Components/ResultadoPDF/Components/ScatterChartPdf'
import { ScatterChart } from '../../Components/ScatterChart/scatter-chart'
import { TabelaMaozinha } from '../../Components/TabelaMaozinha/TabelaMaozinha'
import { IModel, ItemGrafico } from '../../interfaces'

interface iProps {
  Model: IModel
}

export const TabAnaliseEstatistica: React.FC<iProps> = ({ Model }) => {
  const [TemMao, setTemMao] = useState(true)
  const [DadosGraficoScatter, setDadosGraficoScatter] = useState<Array<{name: string, data: number[][]}>>()
  const [ScatterGrafico, setScatterGrafico] = useState<string>('')
  const [GraficoMediaPerguntas, setGraficoMediaPerguntas] = useState<string>('')
  const [GraficoMediaPerguntasAlavancagem, setGraficoMediaPerguntasAlavancagem] = useState<string>('')

  function ObterLabel(dados: ItemGrafico[]): Array<{name: string, data: number[][]}> {
    const dataChart: any[] = []

    if (dados != null) {
      dados.forEach(e => {
        const dd = e.titulo.split('/')
        if (dd.length > 1) {
          e.titulo = ''
          dd.forEach(item => {
            e.titulo += item + '\n'
          })
        }
        const newValor = [e.valorX, e.valor]

        dataChart.push({ name: e.titulo, data: [newValor] })
      })
    }

    return dataChart
  }

  useEffect(() => {
    if (Model.graficoDispersaoAfetamEngajamento.dados) {
      setDadosGraficoScatter(ObterLabel(Model.graficoDispersaoAfetamEngajamento.dados))
    }
  }, [Model.graficoDispersaoAfetamEngajamento.dados])
  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <Flex pos={'absolute'} left={-299999}>
          <BarChartPDF
            id='questoes-afetam-alavancagem-analise-pdf'
            setGrafico={setGraficoMediaPerguntasAlavancagem}
            dados={Model.graficoDispersaoAfetamEngajamento.dados}
            alavancagem
          />
        </Flex>
        <Flex pos={'absolute'} left={-199999}>
          <BarChartPDF
            id='questoes-afetam-analise-pdf'
            dados={Model.graficoDispersaoAfetamEngajamento.dados}
            setGrafico={setGraficoMediaPerguntas}
          />
        </Flex>
        <Flex pos={'absolute'} left={-99999}>
          <ScatterChartPDF
            id='scatter-chart-analise'
            dados={Model.graficoDispersaoAfetamEngajamento.dados}
            setGrafico={setScatterGrafico}
          />
        </Flex>
        <Flex justifyContent={'end'} w={'full'}>
          <PDFDownloadLink
            document={
              <AnaliseEstatisticaPDF
                model={Model}
                graficoMediaPerguntas={GraficoMediaPerguntas}
                graficoMediaPerguntasAlavancagem={GraficoMediaPerguntasAlavancagem}
                scatterGrafico={ScatterGrafico}/>
            }
            fileName={'exportar_analise_estatistica_pec.pdf'}
          >
            {({ loading }) =>
              (loading)
                ? (
                  <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'} isLoading>Exportar PDF de análise estatística</ButtonCmp>
                  )
                : <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'}>Exportar PDF de análise estatística</ButtonCmp>

            }
          </PDFDownloadLink>
        </Flex>
      </Flex>
      {Model.graficoDispersaoAfetamEngajamento.dados?.length > 0 && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} mt={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'} my={'1rem'}>
          <h4>2° passo - Após processar as correlações é realizado o corte das perguntas que afetam mais o engajamento</h4>
        </Flex>
        <ScatterChart
          id='scatter-chart-analiseEstatistica'
          dados={Model.graficoDispersaoAfetamEngajamento.dados}
        />
        <Flex justifyContent={'center'} fontWeight={'700'}>Importância relativa (alavancagem)</Flex>
        <Flex flexDir={'column'} gap={'.75rem'} px={'1rem'}>
          {DadosGraficoScatter?.map(e => {
            return (
              <Flex fontSize={'14px'}>{e.name}: ( Alavancagem: {e.data[0][0]}, Engajamento: {e.data[0][1]} )</Flex>
            )
          })}
        </Flex>
      </Flex>}
      {Model.graficoDispersaoAfetamEngajamento.dados?.length > 0 && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
          </Flex>
          <h3>Média das questões que afetam engajamento</h3>
        </Flex>
        <BarChart
          id='questoesAfetamAnaliseEstatistica'
          dados={Model.graficoDispersaoAfetamEngajamento.dados}
        />
      </Flex>}

      {Model.graficoDispersaoAfetamEngajamento.dados?.length > 0 && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
          </Flex>
          <h3>Alavancagem das questões que afetam engajamento</h3>
        </Flex>
        <BarChart
          id='alavancagemQuestoesAfetamAnaliseEstatistica'
          dados={Model.graficoDispersaoAfetamEngajamento.dados}
          alavancagem
        />
      </Flex>}

      {Model.atributosQueAfetamMaisEngajamento?.length > 0 && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--Orange)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'} color={'var(--Orange)'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaChartLine fontSize={'2rem'}/>
          </Flex>
          <h3>Atributos com maior impacto no engajamento</h3>
        </Flex>
        <Text>Esses são os atributos que, estatisticamente possuem o maior impacto no engajamento, ou seja, o sentimento de um colaborador relacionado a esse atributo afeta o engajamento dentro da organização.</Text>
        <Grid gridTemplateColumns={'repeat(5, 1fr)'} gap='2rem 0rem' px={'2rem'}>
          {Model.atributosQueAfetamMaisEngajamento?.map(e => {
            return (
              <GridItem display={'flex'} flexDir='column' gap={'.75rem'} w={'8.5rem'} alignItems={'center'}>
                <Flex w={'8rem'} h={'8rem'} borderRadius={'50%'} alignItems={'center'} justifyContent={'center'} p={'.325rem'} border={'2px dashed var(--c6)'}>
                  <Img w={'7rem'} h={'7rem'} borderRadius={'50%'} src={e.avatar}/>
                </Flex>
                <Text color={'var(--Gray2)'} fontWeight={700} wordBreak={'break-word'}>{e.nome}</Text>
              </GridItem>
            )
          })}
        </Grid>
        <Divider borderColor={'var(--Orange)'} my={'2rem'} opacity={'1'}/>
      </Flex>}

      {!(Model.atributosQueAfetamMaisEngajamento?.length > 0) && <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>}
      <Flex gap={'.75rem'} alignItems={'center'} mb={'2rem'}>
        <h4>3° passo - Após a identificação das perguntas priorizadas para serem trabalhadas na empresa e pesquisados na Pulse, identificamos se e quais grupos avaliam melhor ou pior significativamente cada tema através da análise de variância de cada grupo (ANOVA)</h4>
      </Flex>

      {!Model.pesquisa.pesqusiaManual && <Flex flexDir={'column'} gap={'1.325rem'}>
        {(Model.perguntasMaiorAlavancagem.length > 0 || (Model.quatroPiores && Model.quatroPiores?.length > 0))
          ? <>
          <Flex gap={'.75rem'} alignItems={'center'}>
            <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
              <FaChartArea fontSize={'2rem'} color={'var(--Azul)'}/>
            </Flex>
            <h3>Diferença estatística dos grupos de análise</h3>
          </Flex>
          <Text>Abaixo identificamos diferenças estatísticas significativas entre as notas médias dos grupos de análises.</Text>
          <Flex justifyContent={'center'} alignItems='center' border={'1px dashed var(--Azul)'} background={'var(--AzulOpacoBg)'} p={'.75rem 1.5rem'} flexDir={'column'} gap={'.75rem'}>
            <Text color={'var(--Azul)'} fontWeight={700} fontSize={'22px'}>Legenda</Text>
            <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1.5rem'}>
              <GridItem background={'var(--c2)'} border={'1px solid var(--c6)'} padding={'1rem 1.75rem'} display={'flex'} borderRadius={'.325rem'} alignItems={'center'}>
                <Flex minW={'5.5rem'} alignItems='center'>
                  <FaThumbsUp color='var(--Green2)' fontSize={'4.5rem'}/>
                </Flex>
                <Text fontSize={'18px'} textAlign='justify'>
                  O grupo possui uma nota estatisticamente <Text as={'span'} color={'var(--Green2)'} fontWeight={700}>maior</Text> que os demais
                </Text>
              </GridItem>
              <GridItem background={'var(--c2)'} border={'1px solid var(--c6)'} padding={'1rem 1.75rem'} display={'flex'} borderRadius={'.325rem'} alignItems={'center'}>
                <Flex minW={'5.5rem'} alignItems='center'>
                  <FaThumbsDown color='var(--Orange)' fontSize={'4.5rem'}/>
                </Flex>
                <Text fontSize={'18px'} textAlign='justify'>
                  O grupo possui uma nota estatisticamente <Text as={'span'} color={'var(--Orange)'} fontWeight={700}>menor</Text> que os demais
                </Text>
              </GridItem>
              <GridItem background={'var(--c2)'} border={'1px solid var(--c6)'} padding={'1rem 1.75rem'} display={'flex'} borderRadius={'.325rem'} alignItems={'center'}>
                <Flex minW={'5.5rem'} alignItems='center' justifyContent={'center'} mr={'.75rem'}>
                  <Box w={'80%'} h={'4px'} background={'var(--c6)'} borderRadius={'12px'}></Box>
                </Flex>
                <Text fontSize={'18px'} textAlign='justify'>
                  O grupo <Text as={'span'} color={'var(--c6)'} fontWeight={700}>não</Text> possui diferença estatística
                </Text>
              </GridItem>
            </Grid>
          </Flex>
          <Flex flexDir={'column'} gap={'3rem'} mt={'1.5rem'}>
            {TemMao
              ? GroupBy(Model.demografiasDDLMAOS, (item) => item.demografiaId).toList().map((e, i) => {
                const length = GroupBy(Model.demografiasDDLMAOS, (item) => item.demografiaId).toList().length
                if (!e.data.some(eData => Model.demografiasMaisCincoRespostas.some(dem => eData.pergunta === dem))) {
                  if ((i + 1) === length) {
                    setTemMao(false)
                  }
                  return null
                }
                return (
                  <Flex flexDir={'column'} gap={'1rem'}>
                    <Flex flexDir={'column'}>
                      <Flex gap={'.75rem'} alignItems='center'>
                        <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                          <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
                        </Flex>
                        <h3>{e.data[0].pergunta}</h3>
                      </Flex>
                    </Flex>
                    <TabelaMaozinha
                      demografias={Model.demografiasDDLMAOS}
                      maozinhas={Model.maozinhas}
                      perguntasAlavancagem={Model.perguntasMaiorAlavancagem}
                    />
                  </Flex>
                )
              })
              : <Flex justifyContent={'center'} alignItems={'center'} fontSize={'20px'} fontWeight={700}>
              Nenhuma das demografias apresentou diferença estatística
            </Flex>}
          </Flex>
        </>
          : <Flex justifyContent={'center'} alignItems={'center'} fontSize={'20px'} fontWeight={700}>
          Nenhuma das demografias apresentou diferença estatística
        </Flex>}
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.25rem'} alignItems={'center'} flexDir={'column'} maxW={'66rem'} margin={'0 auto'} justifyContent={'center'}>
          <h3 style={{ textAlign: 'center' }}>Agora você pode adicionar planos de ação para trabalhar as questões apontadas em sua pesquisa de engajamento!</h3>
          <span style={{ textAlign: 'center' }}>Recomendamos que foque principalmente nas questões que afetam o engajamento na sua organização, ou seja, as questões que os respondentes realmente consideram importantes.</span>
          <ButtonCmp leftIcon={<FaPlus/>} VarColor='Green2' w={'12rem'} my={'.75rem'}>Adicionar plano de ação</ButtonCmp>
        </Flex>
      </Flex>}

    </>
  )
}
