import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'

interface iFormProps {
  placeholder: string
  width?: string
  OnChange: (e: string) => void
  EnterAction: () => void
}

export const SearchCmp: React.FC<iFormProps> = ({ placeholder, width, OnChange, EnterAction }) => {
  return (
      <InputGroup w={width ?? '17rem'}>
        <InputLeftElement
          pointerEvents='none'
          h={'1.925rem'}
          w={'2rem'}
          fontSize={'.875rem'}
          children={<FaSearch color='var(--Gray4)' size={'11'}/>}
        />
        <Input
          bg={'white'}
          fontSize={'.875rem'}
          onChange={(e) => OnChange(e.target.value)}
          borderColor={'var(--Gray4)'}
          type='text'
          size={'xs'}
          pl={'2rem'}
          transition={'none'}
          h={'1.925rem'}
          placeholder={placeholder}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              EnterAction()
            }
          }}
        />
      </InputGroup>
  )
}
