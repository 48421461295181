import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { iEscala, iEtapa } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Legenda, Main } from './styles'
import { Flex, FormControl, useToast, Text, Grid, GridItem } from '@chakra-ui/react'
import { ValidarEtapa } from '../../../Utils/Helper'
import { CreateEtapaBox } from '../../../components/CreateEtapaBox'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { iPesosAvaliadores } from '../EnviarPesquisa'
import { InputCmp } from '../../../components/InputCmp'
import { InfoTooltipCmp } from '../../../components/InfoTooltipCmp'
import { CheckboxCmp } from '../../../components/CheckboxCmp'
import { AccordionContainer } from '../../../components/AccordionContainer'
import { SelectCmp } from '../../../components/SelectCmp'
import { BoxEscala } from '../../../components/BoxEscala'

interface iPesquisaPercepcao {
  nomePesquisa: string
  escalaId: string
  participantes: number[]
  pesosAvaliadores?: iPesosAvaliadores[]
  permitirAlterarPeso?: boolean
}

type iLocationProps = {
  trilhaId: string
  processoId: string
  colaboradorId: string
  etapaId: string
}

export const CriarPesquisaQuantitativa: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const path = useLocation().pathname
  const { trilhaId, processoId, etapaId, colaboradorId } = useParams<iLocationProps>()

  const ctDefault = path.includes('Desempenho') ? 'AnaliseDesempenho' : path.includes('Percepcao') ? 'Percepcao' : ''
  const trilha = path.includes('Trilha') ? `Trilha/${trilhaId as string}` : path.includes('Processo') ? `Processo/${colaboradorId as string}` : undefined

  const [Pesquisa, setPesquisa] = useState<iPesquisaPercepcao>({
    nomePesquisa: '',
    escalaId: '',
    participantes: [],
    permitirAlterarPeso: false
  })

  const [Etapa, setEtapa] = useState<iEtapa>({})

  const urlPai = processoId ? 'PesquisaPercepcaoEtapaColaborador' : 'PesquisaPercepcaoEtapa'

  const [EtapaCriacao, setEtapaCriacao] = useState(1)

  const [isValid, setisValid] = useState(true)
  const [Escalas, setEscalas] = useState<iEscala[]>([])
  const [TemResposta, setTemResposta] = useState(false)

  const handleCheckboxParticipanteClick = (value: number): void => {
    if (Pesquisa.participantes.find(e => e === value) !== undefined) {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [] })
      } else {
        const copy = [...Pesquisa.participantes].filter(e => e !== value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    } else {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [0, 1, 2, 3, 4, 6, 7] })
      } else {
        const copy = [...Pesquisa.participantes]
        copy.push(value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    }
  }

  const isRadioEscalaSelected = (value: string): boolean => Pesquisa.escalaId === value

  function criarPesquisa(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0 || (!path.includes('Jornada') && Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0)) {
      toast({
        title: 'Selecione no mínimo um stakeholder' + ((!path.includes('Jornada') && Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0) ? ' além da autoavaliação' : ''),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.escalaId === '') {
      toast({
        title: 'Selecione uma escala para esta pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    appApi.post(`Pesquisa${ctDefault}`, {
      ...Pesquisa,
      participantes: [...Pesquisa.participantes.filter(r => r !== 7)]
    })
      .then(response => {
        nav(`${path.replace('Criar', 'Formularios')}/${response.data as string}`)
      }).catch(({ response }) => {
        console.log(response)
        toast({
          title: 'Erro',
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      }).finally(() => {
        toast({
          title: 'Pesquisa criada com sucesso',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function CreateEtapaComposta(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0 || (!path.includes('Jornada') && Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0)) {
      toast({
        title: 'Selecione no mínimo um stakeholder' + ((!path.includes('Jornada') && Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0) ? ' além da autoavaliação' : ''),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.escalaId === '') {
      toast({
        title: 'Selecione uma escala para esta pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: {
        ...Pesquisa,
        participantes: [...Pesquisa.participantes.filter(r => r !== 7)]
      },
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(res => {
        nav(`/Jornada/Percepcao/Quantitativa/Formularios/${res.data as string}${trilha !== undefined ? `/${trilha}` : ''}`)
      })
      .catch(err => console.log(err))
  }

  function getEscalas(): void {
    appApi.get('Escala')
      .then(res => setEscalas(res.data))
      .catch(err => console.log(err))
  }

  function getById(): void {
    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.get(`${urlPai}/${param}`)
      .then(res => {
        setPesquisa({
          ...res.data.pesquisa,
          participantes: res.data.pesquisa.participantes.length === 6 ? [...res.data.pesquisa.participantes, 7] : res.data.pesquisa.participantes
        })
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
  }

  function UpdateEtapaComposta(reenviarConvite?: boolean): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: {
        ...Pesquisa,
        participantes: [...Pesquisa.participantes.filter(r => r !== 7)]
      },
      etapa: Etapa
    }

    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.put(`${urlPai}/${param}?reenviarConvite=${reenviarConvite ? 'true' : 'false'}`, form)
      .then(() => {
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  function AtualizarPeso(tipoAvaliado: number, peso: number): void {
    setPesquisa({
      ...Pesquisa,
      pesosAvaliadores: [...Pesquisa?.pesosAvaliadores?.filter(p => p.tipo !== tipoAvaliado) ?? [], { tipo: tipoAvaliado, peso: peso }]
    })
  }

  useEffect(() => {
    getEscalas()

    if (etapaId) {
      getById()
    }

    if (path.includes('Processo')) {
      appApi.get(`PesquisaPercepcaoEtapaColaborador/${processoId ?? ''}/Etapa/${etapaId ?? ''}/Resposta`)
        .then(res => setTemResposta(res.data))
        .catch(err => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (ValidarEtapa(Etapa) || (!(trilhaId ?? processoId) && !(Pesquisa.participantes.length === 0 || (!path.includes('Jornada') && Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0)))) {
      setEtapaCriacao(4)
    } else if (!(Pesquisa.participantes.length === 0 || (!path.includes('Jornada') && Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0))) {
      setEtapaCriacao(3)
    } else if (Pesquisa.nomePesquisa.length >= 5) {
      setEtapaCriacao(2)
    } else if (Pesquisa.nomePesquisa.length < 5) {
      setEtapaCriacao(1)
    }
  }, [Pesquisa, Etapa])

  return (
    <Body>
      <Main>
        <h1>Criar pesquisa</h1>
        <Flex flexDir={'column'} gap={'1.25rem'}>

          <WhiteContainer IsActive={EtapaCriacao === 1}>
            <FormControl mb={'.25rem'} isInvalid={!isValid}>
              <Flex gap={'.325rem'} alignItems={'center'}>
                <h3>Nome</h3>
                <InfoTooltipCmp VarColor='Green2' label='Coloque o título da pesquisa, ele deve conter pelo menos 5 caractéres'/>
              </Flex>
              <InputCmp
                mt={'.25rem'}
                borderColor={'var(--Gray4)'}
                type={'text'}
                value={Pesquisa.nomePesquisa}
                OnChange={(e) => setPesquisa({ ...Pesquisa, nomePesquisa: e })}
              />
            </FormControl>
          </WhiteContainer>

          {
            (path.includes('Criar') || path.includes('Trilha') || path.includes('Processo')) && (
              <WhiteContainer IsActive={EtapaCriacao === 2} IsDisabled={EtapaCriacao > 1 ? undefined : 'Complete a etapa anterior para seguir com a criação da pesquisa'}>
                <Flex flexDir={'column'}>
                  <h3 style={{ marginBottom: '.125rem' }}>Defina os stakeholders iniciais</h3>
                  <Legenda>Ao adicionar um avaliado, os avaliadores serão preenchidos automaticamente com os eixos selecionados, os demais eixos poderão ser adicionados posteriormente de forma manual.</Legenda>
                  <Flex gap={'.5rem'}>
                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(0)}
                      OnChange={() => handleCheckboxParticipanteClick(0)}
                    >Autoavaliação</CheckboxCmp>

                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(1)}
                      OnChange={() => handleCheckboxParticipanteClick(1)}
                    >Gestor</CheckboxCmp>

                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(2)}
                      OnChange={() => handleCheckboxParticipanteClick(2)}
                    >Pares</CheckboxCmp>

                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(3)}
                      OnChange={() => handleCheckboxParticipanteClick(3)}
                    >Liderados</CheckboxCmp>

                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(4)}
                      OnChange={() => handleCheckboxParticipanteClick(4)}
                    >Outros</CheckboxCmp>

                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(6)}
                      OnChange={() => handleCheckboxParticipanteClick(6)}
                    >Alta liderança</CheckboxCmp>

                    <CheckboxCmp
                      isChecked={Pesquisa.participantes.includes(7)}
                      OnChange={() => handleCheckboxParticipanteClick(7)}
                    >Todos</CheckboxCmp>
                  </Flex>
                </Flex>
                <AccordionContainer title='Configurações avançadas' left>
                  <Flex flexDir={'column'} gap={'.5rem'}>
                    <Text fontWeight={'600'} fontSize={'.875rem'}>Definição do peso da resposta por tipo de avaliador:</Text>
                    <Flex gap={'.75rem'}>
                      <Flex gap={'.25rem'} alignItems={'center'}>
                        <Text fontSize={'.875rem'}>Gestor</Text>
                        <SelectCmp h={'1.5rem'} value={Pesquisa.pesosAvaliadores?.find(e => e.tipo === 1)?.peso?.toString() ?? '1'} OnChange={(e) => AtualizarPeso(1, Number(e))}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.25rem'} alignItems={'center'}>
                        <Text fontSize={'.875rem'}>Pares</Text>
                        <SelectCmp h={'1.5rem'} value={Pesquisa.pesosAvaliadores?.find(e => e.tipo === 2)?.peso?.toString() ?? '1'} OnChange={(e) => AtualizarPeso(2, Number(e))}>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.25rem'} alignItems={'center'}>
                        <Text fontSize={'.875rem'}>Liderados</Text>
                        <SelectCmp h={'1.5rem'} value={Pesquisa.pesosAvaliadores?.find(e => e.tipo === 3)?.peso?.toString() ?? '1'} OnChange={(e) => AtualizarPeso(3, Number(e))}>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.25rem'} alignItems={'center'}>
                        <Text fontSize={'.875rem'}>Outros</Text>
                        <SelectCmp h={'1.5rem'} value={Pesquisa.pesosAvaliadores?.find(e => e.tipo === 4)?.peso?.toString() ?? '1'} OnChange={(e) => AtualizarPeso(4, Number(e))}>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.25rem'} alignItems={'center'}>
                        <Text fontSize={'.875rem'} style={{ whiteSpace: 'nowrap' }}>Alta liderança</Text>
                        <SelectCmp h={'1.5rem'} value={Pesquisa.pesosAvaliadores?.find(e => e.tipo === 6)?.peso?.toString() ?? '1'} OnChange={(e) => AtualizarPeso(6, Number(e))}>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                    </Flex>
                    <CheckboxCmp
                      isChecked={Pesquisa?.permitirAlterarPeso}
                      OnChange={() => setPesquisa({ ...Pesquisa, permitirAlterarPeso: !Pesquisa?.permitirAlterarPeso })}
                    >Permitir mudança de pesos na hora da priorização das competências</CheckboxCmp>
                  </Flex>
                </AccordionContainer>
              </WhiteContainer>
            )
          }

          {
            (trilhaId ?? processoId) && (
              <WhiteContainer IsActive={EtapaCriacao === 3} IsDisabled={EtapaCriacao > 2 ? undefined : 'Complete a etapa anterior para seguir com a criação da pesquisa'}>
                <CreateEtapaBox
                  EditEtapa={Etapa}
                  ignoreId={etapaId}
                  variant='Etapa'
                  onModelChange={AtualizarEtapa}
                  keyWord='pesquisa'
                />
              </WhiteContainer>
            )
          }

          <WhiteContainer IsActive={EtapaCriacao === 4} IsDisabled={EtapaCriacao > 3 ? undefined : 'Complete a etapa anterior para seguir com a criação da pesquisa'}>
            <Flex flexDir={'column'} gap={'.5rem'}>
              <h3>Escolha uma escala</h3>
              <Grid templateColumns={'repeat(3, 1fr)'} className={TemResposta ? 'disabled' : ''} gap={'1rem'}>
                {
                  Escalas.map((e: iEscala, i) => {
                    if (e.fator !== 1) {
                      return (
                        <GridItem>
                          <BoxEscala
                            key={i}
                            Escala={e}
                            Selecionado={isRadioEscalaSelected(e.id)}
                            onClick={() => setPesquisa({ ...Pesquisa, escalaId: e.id })}
                          />
                        </GridItem>
                      )
                    } else {
                      return (<></>)
                    }
                  })
                }
              </Grid>
            </Flex>
          </WhiteContainer>
        </Flex>

        <Flex justifyContent={'end'} marginTop='1rem' gap={'.5rem'}>
          <ButtonCmp
            size={'lg'}
            VarColor={'c6'}
            onClick={() => nav(-1)}
          >Voltar</ButtonCmp>
          {
            (trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
              <ButtonCmp
                onClick={criarPesquisa}
                size={'lg'}
                VarColor='Green2'
              >Criar pesquisa</ButtonCmp>
            )
          }

          {
            ((trilhaId ?? processoId) && etapaId === undefined) && (
              <ButtonCmp
                onClick={CreateEtapaComposta}
                size={'lg'}
                VarColor='Green2'
              >Criar etapa</ButtonCmp>
            )
          }

          {
            (etapaId) && (
              <ButtonCmp
                onClick={() => UpdateEtapaComposta()}
                VarColor={'Rosa'}
              >Atualizar etapa</ButtonCmp>
            )
          }
          {
            (etapaId) && (
              <ButtonCmp
                onClick={() => UpdateEtapaComposta(true)}
                VarColor='Green2'
              >Atualizar etapa e reenviar convites</ButtonCmp>
            )
          }
        </Flex>
      </Main>
    </Body>
  )
}
