/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import Roboto from '../../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  G,
  Image as Img,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React from 'react'
import { Header } from './Components/Header'
import { Footer } from './Components/Footer'
import { IModel } from '../../interfaces'
import { ArrayToChunks, formatarData, GroupBy, PropInvalida } from '../../../../../../Utils/Helper'

interface iProps {
  model: IModel
  graficosDemografia: string[]
  graficosPerguntasAtributo: string[]
  jpgBonequinhos: string[]
  scatterGrafico: string
  graficoMediaPerguntas: string
  graficoMediaAtributos: string
}

export const ResultadoPDF: React.FC<iProps> = ({
  model,
  graficosDemografia,
  graficosPerguntasAtributo,
  jpgBonequinhos,
  scatterGrafico,
  graficoMediaPerguntas,
  graficoMediaAtributos
}) => {
  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  function GetAdesao(): string {
    if (model.pesquisa.pesqusiaManual) {
      return model.adesaoManual.toFixed(2) + '%'
    } else {
      return Math.floor(model.participantes / model.pesquisa.convites * 100).toFixed(2) + '%'
    }
  }

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  })

  Font.registerHyphenationCallback((e) => {
    if (e.includes('http') && e.length > 100) {
      const arr: string[] = []
      for (let i = 0; i < e.length; i += 100) {
        arr.push(e.substring(i, i + 100))
      }
      return arr
    }

    if (e.includes('Flexibilidade/Adaptabilidade')) {
      return e.split('/')
    }

    if (e.includes('Autodesenvolvimento')) {
      return ['Autodesenvolvi', 'mento']
    }

    if (e.length === 1) {
      return [e, '']
    }
    return [e]
  })

  function ObterLogo(): string {
    const logo = localStorage.getItem('logo') ?? ''
    if (PropInvalida(logo)) {
      return 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'
    }

    if (logo.includes('https://blob-hml.icecloud.com.br') || logo.includes('https://blob.icecloud.com.br')) {
      return logo.replace('https://blob-hml.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah').replace('https://blob.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah')
    } else if (logo?.includes('https://sigah.arquiteturahumana.com.br')) {
      return logo.replace('https://sigah.arquiteturahumana.com.br', 'https://sigah.blob.core.windows.net/sigah')
    }
    return logo
  }

  return (
    <Document>
      <Page style={{ backgroundColor: '#E9E9E9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
        <View style={{ display: 'flex', minHeight: '180px', maxHeight: '180px', flexDirection: 'column', paddingHorizontal: 42, paddingTop: 24, gap: '12px', alignItems: 'center' }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/logo_ah_1 2_4b818f42-515f-4165-b395-8ddfa2d4a6e9.png'} style={{ maxWidth: '120px', objectFit: 'contain', maxHeight: '70px' }}/>
          <Text style={{ color: '#66046B', fontSize: 18 }}>Resultados pesquisa de engajamento</Text>
          <Text style={{ color: '#66046B', fontSize: 24 }}>{model.pesquisa.descricao}</Text>
        </View>
        <View style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', paddingHorizontal: 42, paddingVertical: 30 }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/Group 522_619b88f1-5bf2-49f2-9b7a-7c1137c4f43d.png'} style={{ width: '330px', objectFit: 'contain' }}/>
        </View>
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', position: 'relative', minHeight: '320px', maxHeight: '320px', paddingBottom: '42px', paddingRight: '30px' }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/Clip path group_b9e6b36d-1d66-4154-b9da-b32c9f301ebe.png'} style={{ width: '100%', objectFit: 'cover', position: 'absolute', zIndex: 1, bottom: 0, right: 0 }}/>
          <Img src={ObterLogo()} style={{ height: '120px', objectFit: 'contain' }}/>
        </View>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <Text
              style={{
                textAlign: 'left',
                fontFamily: 'Roboto',
                paddingVertical: 32,
                fontSize: 18,
                color: '#203864'
              }}
            >
              Contexto
            </Text>
            <View>
              <Text style={{ fontSize: '14px', fontWeight: 'thin', textAlign: 'justify' }}>
                Após a aplicação da Pesquisa de Engajamento e Clima - {model.nomeEmpresa}
                realizada do dia {formatarData(model.pesquisa.dataInicio ?? '')} a {formatarData(model.pesquisa.dataFim ?? '')}, com a participação de {model.participantes} colaboradores e {GetAdesao()} de adesão, foram realizados estudos estatísticos com os
                atributos correlacionados com o engajamento.
                Identificamos quais atributos afetam o engajamento de forma significativa e qual
                a importância (alavancagem) de cada um.
                Sugerimos que esses atributos sejam priorizados no plano de ação de curto e
                médio prazo.
                Os resultados são uma fonte importante de informação estratégica para a
                empresa.
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: '14px', fontWeight: 'thin', textAlign: 'justify', paddingVertical: 32 }}>
                Após a aplicação da Pesquisa de Engajamento e Clima - {model.nomeEmpresa}
                realizada do dia {formatarData(model.pesquisa.dataInicio ?? '')} a {formatarData(model.pesquisa.dataFim ?? '')}, com a participação de {model.participantes} colaboradores e {GetAdesao()} de adesão, foram realizados estudos estatísticos com os
                atributos correlacionados com o engajamento.
                Identificamos quais atributos afetam o engajamento de forma significativa e qual
                a importância (alavancagem) de cada um.
                Sugerimos que esses atributos sejam priorizados no plano de ação de curto e
                médio prazo.
                Os resultados são uma fonte importante de informação estratégica para a
                empresa.
              </Text>
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <Text
              style={{
                textAlign: 'left',
                fontFamily: 'Roboto',
                paddingVertical: 32,
                fontSize: 18,
                color: '#203864'
              }}
            >
              Atributos com maior impacto no engajamento
            </Text>
            {ArrayToChunks(model.atributos, 4).map(linha => {
              return (<View style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                {linha.map(item => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: '120px' }}>
                      <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Img src={item.avatar} style={{ width: '100%', height: '100%' }}/>
                      </View>
                      <Text style={{ width: '120px', fontSize: '12px', textAlign: 'center' }}>{item.nome}</Text>
                    </View>
                  )
                })}
              </View>)
            })}
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      {ArrayToChunks(graficosDemografia, 2).map(page => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Perfil dos participantes
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                A distribuição dos respondentes desta pesquisa para as demografias definidas
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
            {page.map(e => {
              return <Img src={e} style={{ minHeight: '220px', maxHeight: '220px', objectFit: 'contain' }}/>
            })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
      {jpgBonequinhos && <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Qual é o nível de engajamento dos participantes
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                Distribuição das notas paras as 4 perguntas de engajamento e a distribuição geral das
                notas de engajamento, sendo: favorável (10 ou 8), neutro (6 ou 4) e desfavorável (2 ou 0)
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                {ArrayToChunks(model.bonecos, 4).map(linha => {
                  return (<View style={{ display: 'flex', flexDirection: 'row', gap: '22px' }}>
                    {linha.map((item, i) => {
                      return (<View style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '110px' }}>
                        <Img src={jpgBonequinhos[i]}/>
                        <Text style={{ textAlign: 'center', fontSize: '11px', color: '#6c757d' }}>{item.titulo}</Text>
                      </View>)
                    })}
                  </View>)
                })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>}
      {scatterGrafico.length > 5 && <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Priorização das questões de engajamento
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                Apresentação das questões priorizadas após a análise, com as suas médias e alavancagens
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Img src={scatterGrafico} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
              <Text style={{ fontSize: '12px' }}>Perguntas que afetam o engajamento de forma significativa</Text>
              {model.graficoDispersaoAfetamEngajamento.dados.slice(0, 7).map(e => {
                return <Text style={{ fontSize: '12px' }}>{e.titulo}</Text>
              })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>}
      {scatterGrafico.length > 5 && model.graficoDispersaoAfetamEngajamento.dados.length > 7 && ArrayToChunks(model.graficoDispersaoAfetamEngajamento.dados.filter((e, i) => i > 6), 20).map(page => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ padding: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {page.map(e => {
                return <Text style={{ fontSize: '12px' }}>{e.titulo}</Text>
              })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
      {graficoMediaPerguntas && <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média das perguntas que afetam o engajamento
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Img src={graficoMediaPerguntas} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>}
      {graficoMediaAtributos && <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média dos atributos
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Img src={graficoMediaPerguntas} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 18 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Atributos com maior impacto no engajamento
              </Text>
            </View>
            {ArrayToChunks(model.atributosQueAfetamMaisEngajamento, 5).map(linha => {
              return (<View style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                {linha.map(item => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: '110px' }}>
                      <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '52px', height: '52px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Img src={item.avatar} style={{ width: '100%', height: '100%' }}/>
                      </View>
                      <Text style={{ width: '120px', fontSize: '11px', textAlign: 'center' }}>{item.nome}</Text>
                    </View>
                  )
                })}
              </View>)
            })}
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>}
      {ArrayToChunks(model.mediaAtributosPorPergunta, 2).map((page, i) => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média das perguntas por atributo
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                A distribuição dos respondentes desta pesquisa para as demografias definidas
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
            {page.map((e, index) => {
              return <View style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '12px', width: '110px' }}>
                  <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '100%', height: '52px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Img src={e.avatar} style={{ width: '100%', height: '100%' }}/>
                  </View>
                  <Text style={{ width: '120px', fontSize: '11px', textAlign: 'center' }}>{e.titulo}</Text>
                </View>
                <Img src={graficosPerguntasAtributo[index * i]} style={{ minHeight: '190px', maxHeight: '190px', objectFit: 'contain' }}/>
              </View>
            })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
    </Document>
  )
}
