import { Avatar, Box, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaCheck, FaChevronDown, FaChevronUp, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { IoPersonAddSharp } from 'react-icons/io5'
import { MdBlock } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { IEmployee } from '../../../../../../interfaces'
import { parseJwt } from '../../../../../../services/token'
import { PropInvalida } from '../../../../../../Utils/Helper'

import {
  AvatarColumn,
  Container,
  SmallColumn,
  SmallColumnHeader
} from './styles'

interface IEmployeeTableProps {
  employeeList: IEmployee[]
  ordenacao?: boolean
  desligados?: boolean
  ultimoFiltro?: number
  onEdit: (employee: IEmployee) => void
  onDelete: (employee: IEmployee) => void
  onOrdenar: (filtro: number, ascendente: boolean) => void
  onRealocar: (id: string) => void
}

export const EmployeeTable: React.FC<IEmployeeTableProps> = ({
  employeeList,
  onEdit,
  ordenacao,
  ultimoFiltro,
  onDelete,
  onOrdenar,
  desligados,
  onRealocar
}) => {
  const [Ordenacao, setOrdenacao] = useState(ordenacao ?? false)
  const [UltimoFiltro, setUltimoFiltro] = useState<number>(ultimoFiltro ?? 1)

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
    onOrdenar(filtro, !(!Ordenacao && UltimoFiltro !== filtro) ? !Ordenacao : Ordenacao)
  }

  function ReturnIcon(UltimoFiltro: number): any {
    return UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp size={14} style={{ marginTop: '4px' }} /> : <FaChevronDown size={14} style={{ marginTop: '4px' }} />
  }

  const { Permissoes } = useAuth()
  const nav = useNavigate()
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th> </th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(1)}>Nome {ReturnIcon(1)}</Flex></th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(2)}>Email {ReturnIcon(2)}</Flex></th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(3)}>Função {ReturnIcon(3)}</Flex></th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(4)}>Área {ReturnIcon(4)}</Flex></th>
            <SmallColumnHeader><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(5)}>Gestor {ReturnIcon(5)}</Flex></SmallColumnHeader>
            {parseJwt().adm === 'True' && <th> </th>}
          </tr>
        </thead>

        <tbody>
          {employeeList.map((employee) => (
            <tr key={employee.id}>
              <AvatarColumn style={{ borderLeft: employee.primeiroAcesso ? '2px solid var(--Azul)' : '2px solid var(--Orange)' }} onClick={() => {
                if ((Permissoes.jornada || Permissoes.desempenho) && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if (Permissoes.jornada || Permissoes.desempenho) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <div>
                  <Avatar
                    cursor={'pointer'}
                    src={employee.avatar}
                    name={employee.nome}
                    h={'2rem'}
                    w={'2rem'}
                  />
                </div>
              </AvatarColumn>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if ((Permissoes.jornada || Permissoes.desempenho) && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if ((Permissoes.jornada || Permissoes.desempenho)) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <strong>{employee.nome}</strong>
              </td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if ((Permissoes.jornada || Permissoes.desempenho) && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if ((Permissoes.jornada || Permissoes.desempenho)) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <span>{employee.email}</span>
              </td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if ((Permissoes.jornada || Permissoes.desempenho) && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if ((Permissoes.jornada || Permissoes.desempenho)) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <span>{employee.cargo?.nome}</span>
              </td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if ((Permissoes.jornada || Permissoes.desempenho) && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if ((Permissoes.jornada || Permissoes.desempenho)) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <span>{employee.departamento?.nome}</span>
              </td>
              <SmallColumn isActive={employee.gestorDeArea}>
                <FaCheck />
              </SmallColumn>
              {
                parseJwt().adm === 'True' &&
                <SmallColumn>
                  <div>
                    {(PropInvalida(employee.dataDesligamento) && !desligados) && <ElementoTooltipCmp bg={'var(--Azul)'} label='Editar colaborador'>
                      <button type="button" onClick={() => onEdit(employee)}>
                        <FiEdit size={18} color={'var(--Azul)'}/>
                      </button>
                    </ElementoTooltipCmp>}
                    {(PropInvalida(employee.dataDesligamento) && !desligados) && <ElementoTooltipCmp bg={'var(--Red)'} label='Excluir colaborador'>
                      <button type="button" onClick={() => onDelete(employee)}>
                        <FaTrashAlt size={18} color={'var(--Red)'}/>
                      </button>
                    </ElementoTooltipCmp>}
                    {(!PropInvalida(employee.dataDesligamento) || desligados) &&
                      <ElementoTooltipCmp label={'Realocar colaborador'} bg={'var(--Azul)'}>
                        <Box>
                          <button type='button'>
                            <IoPersonAddSharp color='var(--Azul)' size={18} onClick={() => onRealocar(employee.id)}/>
                          </button>
                        </Box>
                      </ElementoTooltipCmp>
                    }
                    {(!PropInvalida(employee.dataDesligamento) || desligados) &&
                      <ElementoTooltipCmp label={!PropInvalida(employee.dataDesligamento) ? `Colaborador ${desligados ? '' : 'será'} desligado no dia ${employee.dataDesligamento ?? ''}` : 'Colaborador desligado'} bg={'var(--Red)'}>
                        <Box>
                          <button type='button'>
                            <MdBlock style={{ opacity: '50%', color: 'red' }} size={18}/>
                          </button>
                        </Box>
                      </ElementoTooltipCmp>
                    }
                  </div>
                </SmallColumn>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}
