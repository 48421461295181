import styled from 'styled-components'

export const DashedBox = styled.label`
margin-top: .5rem;
display: flex;
flex-direction: column;
cursor: pointer;
align-items: center;
border: 2px solid var(--Azul);
border-radius: .25rem;
border-style: dashed;
width: 180px;
padding: 1.325rem;
transition: .2s;
color: var(--c7);
background: var(--c2);
small{
  margin-top: .5rem;
  text-align: center;
  font-size: 13px;
  line-height: 100%;
}
:hover{
  color: black;
  font-weight: 700;
  background: var(--AzulOpacoBg);
  border-style: dotted;
  img{
    opacity: .7;
  }
}

img{
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

input[type=file]{
  display:none;
}
`
