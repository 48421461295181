/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { ModalDelete } from '../../../components/ModalDelete'
import { iCardPesquisa } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { CardPesquisa } from './Components/CardPesquisa'
import { Box, Flex, FormControl, Text, useToast } from '@chakra-ui/react'
import { FaFilter, FaPlus } from 'react-icons/fa'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ImgSah } from '../../../components/ImgSah'
import { parseJwt } from '../../../services/token'
import { ModalReabrir } from '../../../components/ModalReabrir'
import { Paginador } from '../../../components/Paginador'
import LoadingContainer from '../../../components/LoadingContainer'
import { SelectCmp } from '../../../components/SelectCmp'
import { SearchCmp } from '../../../components/SearchCmp'

export const GestaoPesquisas: React.FC = () => {
  const { pathname, search } = useLocation()
  const toast = useToast()
  const nav = useNavigate()

  const params = new URLSearchParams(search)
  const filtro = params.get('filtro')

  const [Pesquisas, setPesquisas] = useState<iCardPesquisa[]>([])
  const [SearchText, setSearchText] = useState('')
  const [Tipo, setTipo] = useState('')
  const [Filter, setFilter] = useState('0')
  const [FilterStatus, setFilterStatus] = useState(filtro)
  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(0)
  const [Tamanho, setTamanho] = useState(25)
  const [FiltroNaoEncontrado, setFiltroNaoEncontrado] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalReabrirIsOpen, setModalReabrirIsOpen] = useState(false)
  const [PesquisaId, setPesquisaId] = useState('')

  const ct = pathname.includes('Desempenho') ? 'AnaliseDesempenho' : pathname.includes('Percepcao') ? 'Percepcao' : ''
  const Titulo = pathname.includes('Desempenho') ? 'desempenho por competência' : pathname.includes('Percepcao') ? 'percepção' : ''

  function getPesquisas(): void {
    setIsLoading(true)
    appApi.get(`Pesquisa${ct}/?tamanho=${Tamanho}&pagina=${Pagina}&busca=${SearchText}&ordenador=${ct === 'Percepcao' ? Tipo : Filter}&statusPesquisa=${FilterStatus ?? ''}`)
      .then(response => {
        if (response.data.length > 0 || Pagina === 0) {
          setPesquisas(response.data)
          getQuantidadePesquisas()
        } else {
          setPagina(0)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function getQuantidadePesquisas(): void {
    setIsLoading(true)
    setFiltroNaoEncontrado(false)
    appApi.get(`Pesquisa${ct}/Quantidade/?busca=${SearchText}&ordenador=${ct === 'Percepcao' ? Tipo : Filter}`)
      .then(response => {
        if (response.data === true) {
          console.log(response.data)
          setFiltroNaoEncontrado(response.data)
          setQtd(0)
        } else {
          setQtd(response.data)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function DeletePesquisa(): void {
    appApi.delete(`PesquisaPercepcao/${PesquisaId}`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function OnOpenModalDelete(Id: string): void {
    setPesquisaId(Id)
    setModalDeleteIsOpen(true)
  }

  function OnCloseModalDelete(): void {
    setPesquisaId('')
    setModalDeleteIsOpen(false)
  }

  function OnOpenModalReabrir(Id: string): void {
    setPesquisaId(Id)
    setModalReabrirIsOpen(true)
  }

  function OnCloseModalReabrir(): void {
    setPesquisaId('')
    setModalReabrirIsOpen(false)
  }

  function ReabrirPesquisa(dataFim: string): void {
    setIsLoading(true)
    appApi.put(`Pesquisa${ct}/${PesquisaId}/Reabrir?dataFim=${dataFim}`)
      .then(() => {
        setIsLoading(false)
        toast({
          title: 'Pesquisa reaberta com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav(`/${pathname.includes('Desempenho') ? 'Desempenho' : 'Jornada'}/${ct}/${Pesquisas.find(e => e.id === PesquisaId)?.indicativa ? 'Indicativa' : 'Quantitativa'}/ResultadoPesquisa/${PesquisaId}?modalAlerta=true`)
      })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  useEffect(() => {
    getPesquisas()
  }, [pathname, Pagina, Tamanho])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Você deseja realmente excluir esta pesquisa?'
        onConfirm={DeletePesquisa}
        onRequestClose={OnCloseModalDelete}
        center
      />

      <ModalReabrir
        isOpen={ModalReabrirIsOpen}
        message='Para reabrir a pesquisa, você precisa alterar a data de término, deseja continuar?'
        onConfirm={(e) => ReabrirPesquisa(e)}
        onRequestClose={OnCloseModalReabrir}
      />
      <Flex justifyContent={'space-between'} margin='1.75rem 0rem'>
        <Flex flexDir={'column'}>
          <h1>Gestão de pesquisas de {Titulo}</h1>
          <Text fontSize={'14px'} fontWeight={400} color={'var(--Gray2)'}>Gestão das pesquisas quantitativas (mede o desempenho com precisão numérica) e indicativas (identifica as competências de destaque e para desenvolvimento).</Text>
        </Flex>
        {
          (parseJwt().adm === 'True') && (
            <Flex alignItems={'start'} pt={'.25rem'}>
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaPlus size={11}/>}
                onClick={() => nav(`${pathname.replace('Gestao', 'SelecionarModelo')}`)}
              >Criar pesquisa</ButtonCmp>
            </Flex>
          )
        }
      </Flex>
      <Flex alignItems={'end'} marginBottom='1rem' justifyContent={'space-between'}>
        <Flex width={'50%'} gap={'.5rem'}>
          {
            (!pathname.includes('Desempenho')) && (
              <FormControl width={'15rem'}>
                <SelectCmp OnChange={setTipo} title='Tipo de pesquisa' value={Tipo}>
                  <option value={''}>Todas as pesquisas</option>
                  <option value={'4'}>Nova</option>
                  <option value={'5'}>Vinculada a um processo de onboard</option>
                </SelectCmp>
              </FormControl>
            )
          }

          {
            (pathname.includes('Desempenho')) && (
              <FormControl width={'16rem'}>
                <SelectCmp OnChange={setFilter} title='Ordenação' value={Filter}>
                  <option value={'0'}>Pesquisa mais recente</option>
                  <option value={'1'}>Pesquisa mais antiga</option>
                  <option value={'2'}>A-Z</option>
                  <option value={'3'}>Z-A</option>
                </SelectCmp>
              </FormControl>
            )
          }

          <FormControl width={'16rem'}>
            <SelectCmp OnChange={setFilterStatus} title='Tipo de status' value={FilterStatus ?? ''}>
              <Box as={'option'} value={''}>Todos os status</Box>
              <Box as={'option'} value={'0'}>🟡 Em elaboração</Box>
              <Box as={'option'} value={'1'}>🔴 Em andamento</Box>
              <Box as={'option'} value={'2'}>🟢 Finalizadas</Box>
            </SelectCmp>
          </FormControl>
        </Flex>

        <Flex gap={'.5rem'} justifyContent={'end'}>
          <SearchCmp
            EnterAction={getPesquisas}
            OnChange={setSearchText}
            placeholder={'Pesquisa'}
          />

          <Flex justifyContent='end' alignItems='center'>
            <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { getPesquisas() }}>Filtrar</ButtonCmp>
          </Flex>
        </Flex>

      </Flex>

      <Paginador
        next={(e) => setPagina(e)}
        prev={(e) => setPagina(e)}
        tamanho={Tamanho}
        setTamanho={(e) => setTamanho(e)}
        paginaAtual={Pagina}
        qtdElementos={Qtd}
        hideTop={true}
      >
        <LoadingContainer linhas={18} loading={IsLoading}>
          <Flex flexDir={'column'} gap='.5rem'>
            {Pesquisas.length !== 0 &&
              Pesquisas.map((p: iCardPesquisa, i) => {
                return (
                  <CardPesquisa
                    key={i}
                    pesquisa={p}
                    onDelete={() => OnOpenModalDelete(p.id)}
                    onReabrir={() => OnOpenModalReabrir(p.id)}
                  />
                )
              }
              )
            }
          </Flex>
        </LoadingContainer>
      </Paginador>

      {
        (Pesquisas?.length === 0 && !IsLoading) && (
          <ImgSah entidade={!FiltroNaoEncontrado ? 'pesquisa' : undefined} textoPersonalizado={FiltroNaoEncontrado ? 'Esse filtro não apresentou resultados, deseja criar uma pesquisa nova com esses critérios?' : undefined}>
            {
              (parseJwt().adm === 'True') && (
                <ButtonCmp
                  VarColor='Green2'
                  size={'lg'}
                  onClick={() => nav(`${pathname.replace('Gestao', 'SelecionarModelo')}`)}
                >Criar nova pesquisa</ButtonCmp>
              )
            }
          </ImgSah>
        )
      }
    </Body>
  )
}
