import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SigahApi } from '../../../../../services/appApi'
import { ObterToken, PropInvalida } from '../../../../../Utils/Helper'
import { iCardEtapa, iChartNps } from '../../../../../interfaces'
import { PieChart } from '../../../../Jornada/eNps/Resultado/Components/PieChart'

interface iCard {
  etapa: iCardEtapa
  isOpen: boolean
}

export const CardEnps: React.FC<iCard> = ({ etapa, isOpen }) => {
  const [Grafcos, setGraficos] = useState<iChartNps[]>([])

  function getResultado(): void {
    SigahApi.get(`api/Onboard/eNps/Resultado/${etapa.interacoes[0].idExterno}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        if (!PropInvalida(res.data.graficosNps) && res.data.graficosNps.length > 0) {
          setGraficos(res.data.graficosNps)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && Grafcos.length === 0) {
      getResultado()
    }
  }, [isOpen])
  return (
    <Flex flexDir={'column'} gap={'.75rem'}>
      {
        Grafcos.length > 0 && Grafcos.map((e, i) => {
          return (
            <Flex alignItems={'start'} maxWidth={'30rem'} key={i}
              paddingBottom={'1rem'}
              flexDir={'column'}>
              <Text fontWeight={'500'} fontSize={'14px'} color='var(--Gray2)'>{e.pergunta}</Text>
              <Flex marginTop={'1rem'} justifyContent={'center'} width={'100%'}>
                <PieChart
                  id={`i-ChartNps-${i}`}
                  detratores={e.detratores}
                  neutros={e.neutros}
                  promotores={e.promotores}
                  nps={e.nps.toFixed(0)}
                />
              </Flex>
            </Flex>
          )
        })
      }
      {
        (Grafcos.length === 0) && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            <Text fontSize={'14px'}>Ainda não possui respostas</Text>
          </Flex>
        )
      }
    </Flex>
  )
}
