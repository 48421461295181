/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable array-callback-return */import { Alert, AlertDescription, AlertIcon, AlertTitle, Avatar, Box, Flex, Grid, Text, useToast } from '@chakra-ui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useState, useEffect } from 'react'
import { FaClipboardList, FaFilter, FaPlus, FaRegPlayCircle, FaUsers } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { DashboardSwitch } from '../../../../components/DashboardSwitch'
import { ModalDelete } from '../../../../components/ModalDelete'
import { Tag } from '../../../../components/Tag'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iColaboradorBase } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { iModelAcao } from '../../Criar'
import { DrawerFiltro } from './components/DrowerFiltro'
import { PieChart } from './components/PieChart'
import { RelatorioPDF } from './components/RelatorioPDF'
import { Tabela } from './components/Tabela'
import { BoxContainer, ContainerFiltro, OutlineContainer } from './styles'

interface iPieChart {
  concluidos: number
  emAndamento: number
  atrasados: number
  emAprovacao: number
}

export interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  origem: string
  totalAcoesRealizadas: number
  totalAcoes: number
  prazo: string
  notaUltimaAvaliacao?: number
  status: number
  totalAcoesAtrasadas: number
  acoes: iModelAcao[]
}

interface iDadoTabelaCompetencia {
  assunto: string
  avatar?: string
  descricao?: string
  totalPessoas: number
}

interface iModel {
  totalPDIEmAndamento: number
  totalAcoes: number
  grafico: iPieChart
  competencias: iDadoTabelaCompetencia[]
  dados: iDadoTabela[]
  createPDI: boolean
}

interface iFiltro {
  dataInicio?: string
  dataFim?: string
  status?: string
  area?: string
  squad?: string
  cargo?: string
  origem?: string
  acao?: string
}

interface iPendente {
  colaborador: iColaboradorBase
  pdIs: string[]
  pdiId: string
}

export const DashboardCorporativoPDI: React.FC = () => {
  const nav = useNavigate()

  const [BaixarRelatorio, setBaixarRelatorio] = useState(false)
  const [BuscarDesligados, setBuscarDesligados] = useState(false)
  const [PDIAtivo, setPDIAtivo] = useState<iDadoTabela>()
  const [Busca, setBusca] = useState<string>('')
  const [Model, setModel] = useState<iModel>()
  const [DrowerIsOpen, setDrowerIsOpen] = useState(false)
  const [Pendente, setPendente] = useState<iPendente>()
  const [Tags, setTags] = useState<string[]>([])
  const [LerMais, setLerMais] = useState<string[]>([])

  function ObterTags(model: iFiltro): void {
    setTags([])
    if (model.area) {
      setTags(oldArray => [...oldArray, 'Área'])
    }

    if (model.cargo) {
      setTags(oldArray => [...oldArray, 'Cargo'])
    }

    if (model.dataFim ?? model.dataInicio) {
      setTags(oldArray => [...oldArray, 'Data de início e fim'])
    }

    if (model.origem) {
      setTags(oldArray => [...oldArray, 'Origem'])
    }

    if (model.squad) {
      setTags(oldArray => [...oldArray, 'Squad'])
    }

    if (model.status) {
      setTags(oldArray => [...oldArray, 'Status'])
    }

    if (model.acao) {
      setTags(oldArray => [...oldArray, 'Acao'])
    }
  }

  function getDashboard(): void {
    appApi.get('PDI/Dashboard/Corporativo')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else { setModel(undefined) }
      })
      .catch(err => {
        console.log(err)
        setModel(undefined)
      })
  }

  function onBaixarPDF(id: string): void {
    const pdiAtivo = Model?.dados.find(e => e.id === id)
    if (!pdiAtivo) return

    setPDIAtivo(pdiAtivo)
    setBaixarRelatorio(true)
  }

  function OnDelete(): void {
    getDashboard()
    getEnvioPendente()
  }

  useEffect(() => {
    if (BaixarRelatorio && PDIAtivo) {
      setTimeout(() => {
        const botao: any = document.getElementById(`pdf-pdi-${PDIAtivo.id}`)
        console.log(PDIAtivo)
        console.log(botao)
        console.log(botao.href)
        const link = document.createElement('a')
        link.href = botao.href
        link.download = `Relatorio_PDI_${PDIAtivo?.colaborador.nome.replace(/\s/g, '_')}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setTimeout(() => {
          setPDIAtivo(undefined)
          setBaixarRelatorio(false)
        }, 5000)
      }, 1000)
    }
  }, [BaixarRelatorio])

  function FiltrarDashboard(params: iFiltro): void {
    ObterTags(params)

    const filter = `a=1${params.dataInicio === '' ? '' : `&dataInicio=${params.dataInicio ?? ''}`}${params.dataFim === '' ? '' : `&dataFim=${params.dataFim ?? ''}`}${params.origem === '' ? '' : `&origem=${params.origem ?? ''}`}${params.status === '' ? '' : `&status=${params.status ?? ''}`}${params.acao === '' ? '' : `&acao=${params.acao ?? ''}`}${params.area === '' ? '' : `&area=${params.area ?? ''}`}${params.squad === '' ? '' : `&squad=${params.squad ?? ''}`}${params.cargo === '' ? '' : `&cargo=${params.cargo ?? ''}`}`
    appApi.get(`PDI/Dashboard/Corporativo/?${filter}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else { setModel(undefined) }
      })
      .catch(err => {
        console.log(err)
        setModel(undefined)
      })
  }

  function getEnvioPendente(): void {
    appApi.get('PDI/Dashboard/Corporativo/Pendente')
      .then(res => setPendente(res.data))
      .catch(err => {
        console.log(err)
        setPendente(undefined)
      })
  }

  useEffect(() => {
    getDashboard()
    getEnvioPendente()
  }, [])

  return (
    <Body>
      <DrawerFiltro
        isOpen={DrowerIsOpen}
        onClose={() => setDrowerIsOpen(false)}
        onFilter={FiltrarDashboard}
      />
      {/* {Model && Model.dados.map(e => {
        return (<PDFDownloadLink
            document={
              <RelatorioPDF
                pdi={e}
              />
            }
            id={`pdf-pdi-${e.id}`}
          >
            {() =>
              <Box pos={'absolute'}></Box>
            }
          </PDFDownloadLink>)
      }
      )} */}

      <Flex justifyContent={'space-between'}>
        <h1>Desempenho {'>'} PDI</h1>
        {Model?.createPDI && <ButtonCmp onClick={() => nav('/Desempenho/PDI/Criar')} VarColor='Green2' leftIcon={<FaPlus/>}>Criar PDI</ButtonCmp>}
      </Flex>
      <Flex margin={'.5rem 0 1rem 0'}>
        <DashboardSwitch/>
      </Flex>
      {Pendente?.colaborador &&
          <Alert marginBottom={'1rem'} borderRadius={'.25rem'} justifyContent='space-between' status='warning' px={'.75rem'} py={'.5rem'}>
            <Flex fontSize={'.813rem'}>
              <AlertIcon color={'#FF9900'} mr={'0.325rem'}/>
              <AlertTitle>PDI aguardando aprovação!</AlertTitle>
              <AlertDescription>{Pendente.colaborador.nome} está aguardando a aprovação de seu plano. <AlertDescription cursor={'pointer'} textDecoration={'underline'} onClick={() => nav(`/Rh/VisualizarPerfil/${Pendente.colaborador.id ?? ''}/?pdiId=${Pendente?.pdiId ?? ''}`)}>Clique aqui para visualizar o perfil</AlertDescription></AlertDescription>
            </Flex>
            <MdClose onClick={() => setPendente(undefined)} cursor={'pointer'} size={18} />
          </Alert>
        }

      <WhiteContainer>
          <ContainerFiltro>
            <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
              <Flex alignItems={'center'}>
                <Text fontWeight={'500'} fontSize='.875rem' paddingRight={'1rem'} borderRight={'2px solid var(--c5)'}>Filtros aplicados</Text>
                <Flex padding={'0 1rem'} gap={'1rem'}>
                  {Tags.length === 0 && <Tag>Todos</Tag>}

                  {
                    Tags.map((e) => {
                      return (
                        <Tag>{e}</Tag>
                      )
                    })
                  }
                </Flex>
              </Flex>
              <Box color='var(--c6)' _hover={{ color: 'var(--Azul)' }}><FaFilter onClick={() => setDrowerIsOpen(true)} cursor={'pointer'} size={22} /></Box>
            </Flex>
          </ContainerFiltro>
      </WhiteContainer>
      <WhiteContainer margin='1rem 0'>
        <Flex flexDir={'column'} gap={'1rem'}>
          {(Model && (Model.grafico.atrasados > 0 || Model.grafico.concluidos > 0 || Model.grafico.emAndamento > 0 || Model.grafico.emAprovacao > 0))
            ? <Grid gap={'1rem'} templateColumns='auto auto 24rem'>
            <OutlineContainer theme={'c4'}>
              <Flex alignItems={'center'} width={'100%'} flexDir={'column'} gap={'.75rem'}>
                <h3>Total de ações</h3>
                <PieChart
                  atrasados={Model.grafico.atrasados}
                  concluidos={Model.grafico.concluidos}
                  emAndamento={Model.grafico.emAndamento}
                  emAprovacao={Model.grafico.emAprovacao}
                  id='chart-1'
                />
              </Flex>
            </OutlineContainer>
            <OutlineContainer theme={'c4'}>
              <Flex height={'100%'} justifyContent={'center'} gap={'.75rem'} alignItems={'center'}>
                <FaClipboardList size={64} />
                <Flex justifyContent={'center'} flexDir={'column'} maxW={'8.125rem'} color={'var(--Gray1)'}>
                  <Text fontWeight={'800'} fontSize={'2rem'} lineHeight={'2.25rem'}>{Model?.competencias.length ?? '0'}</Text>
                  <Text fontWeight={'500'} fontSize={'1.25rem'} lineHeight={'1.425rem'}>Competências trabalhadas</Text>
                </Flex>
              </Flex>
            </OutlineContainer>
            <Flex flexDir={'column'} gap={'.75rem'}>
              <BoxContainer theme={'Azul'}>
                <Flex height={'100%'} justifyContent={'center'} gap={'.75rem'} alignItems={'center'} padding={'1rem 0'}>
                  <Flex gap={'.5rem'} justifyContent={'center'} alignItems={'center'}>
                    <FaUsers size={42} />
                    <Text fontWeight={'700'} fontSize={'1.325rem'}>{Model?.totalPDIEmAndamento ?? '0'}</Text>
                  </Flex>
                  <Text textAlign={'center'} fontWeight={'500'} fontSize={'.875rem'}>Colaboradores com PDI em andamento</Text>
                </Flex>
              </BoxContainer>

              <BoxContainer theme={'Azul'}>
                <Flex height={'100%'} justifyContent={'center'} gap={'.75rem'} alignItems={'center'} padding={'1rem 0'}>
                  <Flex gap={'.5rem'} justifyContent={'center'} alignItems={'center'}>
                    <FaRegPlayCircle size={42} />
                    <Text fontWeight={'700'} fontSize={'1.5rem'}>{Model?.totalAcoes ?? '0'}</Text>
                  </Flex>
                  <Text textAlign={'center'} fontWeight={'500'} fontSize={'1.25rem'}>Ações de PDI</Text>
                </Flex>
              </BoxContainer>
            </Flex>
          </Grid>
            : <Flex gap={'1rem'}>
            <BoxContainer theme={'Azul'}>
              <Flex height={'100%'} justifyContent={'center'} gap={'.5rem'} alignItems={'center'}>
                <Flex justifyContent={'center'} alignItems={'center'} gap={'.125rem'}>
                  <FaClipboardList size={48} style={{ marginTop: '-.5rem' }}/>
                  <Text fontWeight={'800'} fontSize={'1.5rem'}>{Model?.competencias.length ?? '0'}</Text>
                </Flex>
                <Text fontWeight={'500'} fontSize={'1.25rem'}>Competências trabalhadas</Text>
              </Flex>
            </BoxContainer>
            <BoxContainer theme={'Azul'} style={{ minWidth: '27.5rem' }}>
              <Flex height={'100%'} justifyContent={'center'} gap={'.75rem'} alignItems={'center'} padding={'1rem 0'}>
                <Flex gap={'.5rem'} justifyContent={'center'} alignItems={'center'}>
                  <FaUsers size={48} />
                  <Text fontWeight={'700'} fontSize={'1.5rem'}>{Model?.totalPDIEmAndamento ?? '0'}</Text>
                </Flex>
                <Text textAlign={'center'} fontWeight={'500'} fontSize={'1rem'}>Colaboradores com PDI em andamento</Text>
              </Flex>
            </BoxContainer>

            <BoxContainer theme={'Azul'}>
              <Flex height={'100%'} justifyContent={'center'} gap={'.5rem'} alignItems={'center'} padding={'1rem 0'}>
                <Flex gap={'.5rem'} justifyContent={'center'} alignItems={'center'}>
                  <FaRegPlayCircle size={48} />
                  <Text fontWeight={'700'} fontSize={'1.5rem'}>{Model?.totalAcoes ?? '0'}</Text>
                </Flex>
                <Text textAlign={'center'} fontWeight={'500'} fontSize={'1.25rem'}>Ações de PDI</Text>
              </Flex>
            </BoxContainer>
          </Flex>}
          {
              (Model && Model.competencias.length > 0) && (
                <Flex flexDir={'column'} gap={'.75rem'}>
                  <h3>Em desenvolvimento</h3>
                  <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'.75rem .5rem'}>
                    {/* <TabelaCompetencias dados={Model.competencias} /> */}
                    {Model.competencias.sort((a, b) => b.totalPessoas - a.totalPessoas).slice(0, 6).map(e => {
                      return (
                        <OutlineContainer theme={'AzulOpaco'}>
                          <Flex gap={'1rem'} alignItems={'center'}>
                            <Avatar bg={'white'} width={'2.75rem'} height={'2.75rem'} src={e.avatar}/>
                            <Flex flexDir={'column'}>
                              <Flex gap={'.25rem'} fontSize={'.95rem'} lineHeight={'1.5rem'} alignItems={'center'}>
                                <Text fontWeight={'800'}>{e.assunto} <Text as={'span'} fontWeight={700} color={'var(--Azul)'}>{e.totalPessoas}</Text></Text>
                                <FaUsers size={22} color={'var(--Azul)'}/>
                              </Flex>
                              <Text fontSize={'.875rem'} fontWeight={'400'}>{((e.descricao?.length ?? 0) > 28 && !LerMais.includes(e.assunto)) ? `${e.descricao?.substring(0, 29)}...` : e.descricao}
                                {(e.descricao?.length ?? 0) > 28 && <Text as={'span'} color={'var(--Azul)'} _hover={{ color: 'var(--Azul-hover)', textDecor: 'underline' }} onClick={() => { !LerMais.includes(e.assunto) ? setLerMais([...LerMais, e.assunto]) : setLerMais(LerMais.filter(a => { if (a !== e.assunto) { return a } })) }} cursor={'pointer'} ml={'.25rem'}>{!LerMais.includes(e.assunto) ? 'Leia mais' : 'Ocultar'}</Text>}
                              </Text>
                            </Flex>
                          </Flex>
                        </OutlineContainer>
                      )
                    })}
                  </Grid>
                </Flex>
              )
            }
        </Flex>
      </WhiteContainer>
      <WhiteContainer>
          <Tabela SendTexto={(e) => setBusca(e)} qtdElementos={Model?.dados.filter(e => e.colaborador.nome.toLowerCase().includes(Busca.toLowerCase())).filter(e => e.colaborador.desligado === BuscarDesligados).length ?? 1} OnDelete={OnDelete} pendentes={Pendente?.pdIs ?? []} onBaixarPDF={onBaixarPDF} getQtd={setBuscarDesligados}/>
      </WhiteContainer>
    </Body>
  )
}
