/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, FormControl, FormLabel, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import LoadingContainer from '../../../../components/LoadingContainer'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iChartNps, iNomeId } from '../../../../interfaces'
import { SigahApi, urlSigah } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { ObterToken, PropInvalida } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { ColumnChart } from './Components/ColumnChart'
import { PieChart } from './Components/PieChart'
import { ComentariosContainer } from './styles'

type iParam = {
  pesquisaId: string
}

interface iDiscursiva {
  pergunta: string
  respostas: string[]
}

interface iResultadoNps {
  graficosNps: iChartNps[]
  discursivas: iDiscursiva[]
  filtroDemografia: iNomeId[]
}

export const ResultadoEnpsJornada: React.FC = () => {
  const { adm, depid } = parseJwt()
  const param = useParams<iParam>()
  const Route = `${urlSigah}/PesquisaPulse`
  const redirectPage = (at: string, param: string): any => window.location.href = `${Route}/${at}/${param}`

  const nav = useNavigate()

  const [Resultado, setResultado] = useState<iResultadoNps>()
  const [IsLoading, setIsLoading] = useState(false)
  const [FiltroDemografia, setFiltroDemografia] = useState('')
  const [DemografiaNome, setDemografiaNome] = useState('')

  function getResultado(): void {
    SigahApi.get(`api/Onboard/eNps/Resultado/${param.pesquisaId as string}?itemDemografiaId=${adm === 'True' ? '' : depid}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => setResultado(res.data))
      .catch(err => console.log(err))
  }

  function FiltrarGrafico(id: string): void {
    setIsLoading(true)
    setFiltroDemografia(id)
    setResultado(undefined)
    setTimeout(() => {
      SigahApi.get(`api/Onboard/eNps/Resultado/${param.pesquisaId as string}?itemDemografiaId=${id}`, {
        headers: {
          'content-type': 'application/json',
          authorization: ObterToken()
        }
      })
        .then(res => { setResultado(res.data); setIsLoading(false) })
        .catch(err => { console.log(err); setIsLoading(false) })
    }, 500)
  }

  useEffect(() => {
    getResultado()
  }, [])

  useEffect(() => {
    if (PropInvalida(FiltroDemografia) && adm === 'False') {
      setFiltroDemografia(depid)
    }
  }, [Resultado?.filtroDemografia])

  return (
    <Body>
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <Button onClick={() => nav('/Jornada/eNps')} VarColor="c4" size={'lg'}>Voltar</Button>
          <Button onClick={() => redirectPage('Participantes', param.pesquisaId as string)} VarColor='Green2' size={'lg'}>Gestão de convites</Button>
        </Flex>
        <Flex margin={'2rem 0'}>
          <h1>Jornada {'>'} eNPS {'>'} 90 dias</h1>
        </Flex>

        {
          (Resultado && Resultado?.filtroDemografia?.length > 0) && (
            <FormControl marginBottom={'2rem'}>
              <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Demografia</FormLabel>
              <Select
                isDisabled={adm === 'False'}
                value={FiltroDemografia}
                borderColor={'var(--Gray4)'}
                width={'18rem'} onChange={(e) => {
                  if (e.target.value === '') { getResultado() }
                  setDemografiaNome(Resultado?.filtroDemografia?.find(a => a.id === e.target.value)?.nome ?? '')
                  FiltrarGrafico(e.target.value)
                }}>
                <option value={''}>Nenhum</option>
                {
                  Resultado?.filtroDemografia?.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>{e.nome}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          )
        }
        {
          !(Resultado && Resultado?.filtroDemografia?.length > 0) && (
            <FormControl marginBottom={'2rem'}>
              <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Demografia</FormLabel>
              <Select
                isDisabled
                borderColor={'var(--Gray4)'}
                width={'18rem'}>
                <option value={''}>{DemografiaNome}</option>
              </Select>
            </FormControl>
          )
        }

        <Tabs size='md' variant='enclosed'>
          <TabList marginBottom={'.01rem'}>
            <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem', borderBotto: 'white' }} bg={'var(--c1)'} color={'black'}>Resultados</Tab>
            {
              (Resultado && Resultado.discursivas.length > 0) && (
                <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem' }} bg={'var(--c1)'} color={'black'}>Questões discursivas</Tab>
              )
            }
          </TabList>
          <TabPanels border={'1px solid var(--Gray4)'}>
            <TabPanel flexDir={'column'}>
              <LoadingContainer linhas={16} loading={IsLoading} my='.5rem'>
                {
                  (Resultado) && (
                    <Flex gap={'2rem'} flexDir={'column'} marginBottom={'1rem'} borderBottom={'1px solid var(--Gray4)'}>
                      {
                        Resultado.graficosNps?.map((e, i) => {
                          return (
                            <>
                              <Flex key={i}
                                paddingBottom={'1rem'}
                                flexDir={'column'}>
                                <h3>{e.pergunta}</h3>
                                <Flex marginTop={'1rem'} justifyContent={'center'} width={'100%'}>
                                  <PieChart
                                    id={`i-ChartNps-${i}`}
                                    detratores={e.detratores}
                                    neutros={e.neutros}
                                    promotores={e.promotores}
                                    nps={e.nps.toFixed(0)}
                                  />
                                </Flex>
                              </Flex>
                              {
                                e?.graficosDemografia?.map((a, index) => {
                                  return (
                                    <Flex key={a.nomeDemografia} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'} borderBottom={'1px solid var(--Gray4)'}>
                                      <ColumnChart
                                        id={`${i.toString()}-${index.toString()}`}
                                        Grafico={{
                                          titulo: a.nomeDemografia,
                                          labelX: a.demografias.map((b) => {
                                            return b.nomeItem
                                          }),
                                          series: [
                                            {
                                              nome: 'Processos Absolutos',
                                              dados: a.demografias.map((b) => {
                                                return b.nps
                                              })
                                            }
                                          ]
                                        }}
                                      />
                                    </Flex>
                                  )
                                })
                              }
                            </>
                          )
                        })
                      }
                    </Flex>
                  )
                }
              </LoadingContainer>
            </TabPanel>
            <TabPanel>
              {
                Resultado?.discursivas.map((e, i) => {
                  return (
                    <div key={i}>
                      <Flex flexDir={'column'}>
                        <h3 style={{ color: 'var(--Gray3)' }}>Pergunta:</h3>
                        <Text
                          fontWeight={'400'}
                          style={{ fontSize: '1.2rem', marginTop: '.5rem' }}
                        >{e.pergunta}</Text>
                      </Flex>
                      <ComentariosContainer>
                        <section>
                          <h3>Comentários: {e.respostas.length}</h3>
                          {
                            e.respostas.map((r, i2) => {
                              return (
                                <div key={i2}>
                                  <span>{r}</span>
                                </div>
                              )
                            })
                          }

                        </section>
                      </ComentariosContainer>
                    </div>
                  )
                })
              }
            </TabPanel>
          </TabPanels>
        </Tabs>
      </WhiteBox>
    </Body>
  )
}
