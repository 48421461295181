import {
  Drawer, DrawerBody,
  DrawerOverlay, DrawerContent, DrawerHeader, Flex, FormControl, Text
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'

interface iFiltro {
  dataInicio?: string
  dataFim?: string
  status?: string
  area?: string
  squad?: string
  cargo?: string
  origem?: string
  acao?: string
}

interface iDrawProps {
  isOpen: boolean
  onClose: () => void
  onFilter: (filtro: iFiltro) => void
}

export const DrawerFiltro: React.FC<iDrawProps> = ({ isOpen, onClose, onFilter }) => {
  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Departamentos, setDepartamentos] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])

  const [Filtro, setFiltro] = useState<iFiltro>({
    area: '',
    cargo: '',
    dataFim: '',
    dataInicio: '',
    origem: '',
    squad: '',
    status: '',
    acao: ''
  })

  function GetTags(): void {
    appApi.get('Tag/Select/?tipo=2')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function GetDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setDepartamentos(res.data))
      .catch(err => console.log(err))
  }

  function GetCargos(): void {
    appApi.get('Cargo/Select')
      .then(res => setCargos(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetTags()
    GetDepartamentos()
    GetCargos()
  }, [])

  return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            size={'sm'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader as={Flex} width={'100%'} justifyContent={'space-between'} borderBottom={'2px solid var(--c3)'}>
                    <Text>Filtros</Text>
                    <MdClose onClick={onClose} size={24} color={'var(--Red)'}/>
                </DrawerHeader>

                <DrawerBody>
                    <Flex gap={'1rem'} flexDir={'column'}>
                        <Flex gap={'.75rem'}>
                            <FormControl>
                                <InputCmp label={'Início'} OnChange={(e) => setFiltro({ ...Filtro, dataInicio: e })} type={'date'} borderColor={'var(--Gray4)'} max={'2099-12-31T23:59'}/>
                            </FormControl>

                            <FormControl>
                                <InputCmp label={'Fim'} OnChange={(e) => setFiltro({ ...Filtro, dataFim: e })} type={'date'} borderColor={'var(--Gray4)'} max={'2099-12-31T23:59'}/>
                            </FormControl>
                        </Flex>

                        <Flex gap={'.75rem'}>
                            <FormControl>
                                <SelectCmp title='Status PDI' OnChange={(e) => setFiltro({ ...Filtro, status: e })}>
                                    <option value={''}>Todos</option>
                                    <option value={'1'}>Aguardando aprovação</option>
                                    <option value={'5'}>Em andamento</option>
                                    <option value={'6'}>Atrasado</option>
                                    <option value={'7'}>Concluido</option>
                                </SelectCmp>
                            </FormControl>

                            <FormControl>
                                <SelectCmp title='Status Ações' OnChange={(e) => setFiltro({ ...Filtro, acao: e })}>
                                    <option value={''}>Todos</option>
                                    <option value={'1'}>Aguardando aprovação</option>
                                    <option value={'2'}>Aguardando alteração</option>
                                    <option value={'5'}>Em andamento</option>
                                    <option value={'6'}>Atrasada</option>
                                    <option value={'7'}>Concluída</option>
                                </SelectCmp>
                            </FormControl>
                        </Flex>

                        <Flex gap={'.75rem'}>
                            <FormControl>
                                <SelectCmp title='Área' OnChange={(e) => setFiltro({ ...Filtro, area: e })}>
                                    <option value={''}>Todas</option>
                                    {
                                        Departamentos.map((e, i) => {
                                          return (
                                            <option key={i} value={e.id}>{e.nome}</option>
                                          )
                                        })
                                    }
                                </SelectCmp>
                            </FormControl>

                            <FormControl>
                                <SelectCmp title='Função' OnChange={(e) => setFiltro({ ...Filtro, cargo: e })}>
                                    <option value={''}>Todas</option>
                                    {
                                        Cargos.map((e, i) => {
                                          return (
                                            <option key={i} value={e.id}>{e.nome}</option>
                                          )
                                        })
                                    }
                                </SelectCmp>
                            </FormControl>
                        </Flex>

                        <FormControl>
                            <SelectCmp title='Squad' OnChange={(e) => setFiltro({ ...Filtro, squad: e })}>
                                <option value={''}>Todos</option>
                                {
                                    Tags.map((e, i) => {
                                      return (
                                            <option key={i} value={e.id}>{e.nome}</option>
                                      )
                                    })
                                }
                            </SelectCmp>
                        </FormControl>

                        <FormControl>
                            <SelectCmp title='Origem' OnChange={(e) => setFiltro({ ...Filtro, origem: e })}>
                                <option value={''}>Todas</option>
                                <option value={'0'}>Pesquisa</option>
                                <option value={'1'}>Feedback</option>
                                <option value={'2'}>Outra</option>
                            </SelectCmp>
                        </FormControl>

                        <Flex gap={'.5rem'} justifyContent={'end'}>
                            <ButtonCmp
                                VarColor='c6'
                                onClick={onClose}
                            >Cancelar
                            </ButtonCmp>
                            <ButtonCmp
                                VarColor='Green2'
                                onClick={() => onFilter(Filtro)}
                            >Filtrar
                            </ButtonCmp>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
  )
}
