/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormControl } from '@chakra-ui/form-control'
import { Flex } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/toast'
import { Box } from 'framer-motion'
import { useEffect, useState } from 'react'
import { FaPlus, FaFilter } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ImgSah } from '../../../../components/ImgSah'
import LoadingContainer from '../../../../components/LoadingContainer'
import { ModalDelete } from '../../../../components/ModalDelete'
import { ModalReabrir } from '../../../../components/ModalReabrir'
import { Paginador } from '../../../../components/Paginador'
import { SearchCmp } from '../../../../components/SearchCmp'
import { SelectCmp } from '../../../../components/SelectCmp'
import { iCardPesquisaEngajamento, iPermissoesGestaoEngajamento } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { Body } from '../../../Layouts/Body'
import { CardPesquisa } from './Components/CardPesquisa'

export const GestaoPesquisasEngajamento: React.FC = () => {
  const { pathname } = useLocation()
  const toast = useToast()
  const nav = useNavigate()

  // const params = new URLSearchParams(search)
  // const filtro = params.get('filtro')

  const [Pesquisas, setPesquisas] = useState<iCardPesquisaEngajamento[]>([])
  const [Permissoes, setPermissoes] = useState<iPermissoesGestaoEngajamento>({ apagar: false, csv: false, demoId: '', liberarBase: false })
  const [SearchText, setSearchText] = useState('')
  const [Status, setStatus] = useState('')
  const [Periodo, setPeriodo] = useState('')
  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(0)
  const [Tamanho, setTamanho] = useState(25)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalReabrirIsOpen, setModalReabrirIsOpen] = useState(false)
  const [PesquisaId, setPesquisaId] = useState('')

  function getStatus(): string {
    switch (Status) {
      case '0':
        return 'encerrada=true'
      case '1':
        return 'emAndamento=true'
      case '2':
        return 'selParticipante=true'
      case '3':
        return 'elaboracao=true'
      case '4':
        return 'manual=true'
    }
    return ''
  }

  function getPesquisas(): void {
    setIsLoading(true)
    appApi.get(`Pec?tamanho=${Tamanho}&pagina=${Pagina}&periodo=${Periodo}&nomePesquisa=${SearchText}&${getStatus()}`)
      .then(response => {
        if (response.data.length > 0 || Pagina === 0) {
          setPesquisas(response.data)
        } else {
          setPagina(0)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function getQuantidadePesquisas(): void {
    appApi.get(`Pec/Quantidade?&nomePesquisa=${SearchText}&periodo=${Periodo}&${getStatus()}`)
      .then(response => {
        setQtd(response.data)
      })
      .catch(err => console.log(err))
  }

  function GetPermissoesGestao(): void {
    appApi.get('Pec/Gestao/Permissoes')
      .then(res => {
        setPermissoes(res.data)
      })
      .catch(err => console.log(err))
  }

  function DeletePesquisa(): void {
    appApi.delete(`Pec/${PesquisaId}`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function OnOpenModalDelete(Id: string): void {
    setPesquisaId(Id)
    setModalDeleteIsOpen(true)
  }

  function OnCloseModalDelete(): void {
    setPesquisaId('')
    setModalDeleteIsOpen(false)
  }

  function OnOpenModalReabrir(Id: string): void {
    setPesquisaId(Id)
    setModalReabrirIsOpen(true)
  }

  function OnCloseModalReabrir(): void {
    setPesquisaId('')
    setModalReabrirIsOpen(false)
  }

  useEffect(() => {
    getPesquisas()
    getQuantidadePesquisas()
  }, [pathname, Pagina, Tamanho])

  useEffect(() => {
    GetPermissoesGestao()
    getQuantidadePesquisas()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Você deseja realmente excluir esta pesquisa?'
        onConfirm={DeletePesquisa}
        onRequestClose={OnCloseModalDelete}
        center
      />

      {/* <ModalReabrir
        isOpen={ModalReabrirIsOpen}
        message='Para reabrir a pesquisa, você precisa alterar a data de término, deseja continuar?'
        onConfirm={(e) => ReabrirPesquisa(e)}
        onRequestClose={OnCloseModalReabrir}
      /> */}
      <Flex justifyContent={'space-between'} margin='1.75rem 0rem'>
        <Flex flexDir={'column'}>
          <h1>Gestão de pesquisas de Engajamento</h1>
        </Flex>
        {
          (parseJwt().adm === 'True') && (
            <Flex alignItems={'start'} pt={'.25rem'}>
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaPlus size={11}/>}
                onClick={() => nav(`${pathname.replace('Gestao', 'SelecionarModelo')}`)}
              >Criar pesquisa</ButtonCmp>
            </Flex>
          )
        }
      </Flex>
      <Flex alignItems={'end'} marginBottom='1rem' justifyContent={'space-between'}>
        <Flex width={'50%'} gap={'.5rem'}>
          <FormControl width={'15rem'}>
            <SelectCmp OnChange={setStatus} title='Tipo de pesquisa' value={Status}>
              <option value={''}>Todas as pesquisas</option>
              <option value={'0'}>Encerrada</option>
              <option value={'1'}>Preenchimento</option>
              <option value={'2'}>Selecionando participantes</option>
              <option value={'3'}>Elaboração</option>
              <option value={'4'}>Manual</option>
            </SelectCmp>
          </FormControl>

          <FormControl width={'16rem'}>
            <SelectCmp OnChange={setPeriodo} title='Período' value={Periodo}>
              <option value={''}>Todos</option>
              <option value={'30'}>Últimos 30 dias</option>
              <option value={'60'}>Últimos 2 meses</option>
              <option value={'90'}>Últimos 3 meses</option>
              <option value={'120'}>Últimos 4 meses</option>
              <option value={'150'}>Últimos 5 meses</option>
              <option value={'180'}>Últimos 6 meses</option>
              <option value={'360'}>Último ano</option>
            </SelectCmp>
          </FormControl>
        </Flex>

        <Flex gap={'.5rem'} justifyContent={'end'}>
          <SearchCmp
            EnterAction={getPesquisas}
            OnChange={setSearchText}
            placeholder={'Pesquisa'}
          />

          <Flex justifyContent='end' alignItems='center'>
            <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { getPesquisas(); getQuantidadePesquisas() }}>Filtrar</ButtonCmp>
          </Flex>
        </Flex>

      </Flex>

      <Paginador
        next={(e) => setPagina(e)}
        prev={(e) => setPagina(e)}
        tamanho={Tamanho}
        setTamanho={(e) => setTamanho(e)}
        paginaAtual={Pagina}
        qtdElementos={Qtd}
        hideTop={true}
      >
        <LoadingContainer linhas={18} loading={IsLoading}>
          <Flex flexDir={'column'} gap='.75rem'>
            {Pesquisas.length !== 0 &&
              Pesquisas.map((p: iCardPesquisaEngajamento, i) => {
                return (
                  <CardPesquisa
                    key={i}
                    pesquisa={p}
                    onDelete={() => OnOpenModalDelete(p.id)}
                    onReabrir={() => OnOpenModalReabrir(p.id)}
                    permissoes={Permissoes}
                  />
                )
              }
              )
            }
          </Flex>
        </LoadingContainer>
      </Paginador>

      {
        (Pesquisas?.length === 0 && !IsLoading) && (
          <ImgSah>
            {
              (parseJwt().adm === 'True') && (
                <ButtonCmp
                  VarColor='Green2'
                  size={'lg'}
                  onClick={() => nav(`${pathname.replace('Gestao', 'SelecionarModelo')}`)}
                >Criar nova pesquisa</ButtonCmp>
              )
            }
          </ImgSah>
        )
      }
    </Body>
  )
}
