/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Flex } from '@chakra-ui/react'
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'

interface iProps{
  id: string
  Grafico: iGrafico
}

interface iGrafico{
  labelX: string[]
  series: iSeries[]
  titulo: string
}

interface iSeries{
  nome: string
  dados: number []
}

export const ColumnChart: React.FC<iProps> = ({ id, Grafico }) => {
  function GerarGrafico(): void {
    const options = {
      series: [{
        name: '',
        data: Grafico.series[0].dados.map((e: number) => {
          return e
        })
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [{
              from: -100,
              to: -1,
              color: 'var(--terc3)'
            }, {
              from: 0,
              to: 100,
              color: 'var(--v2)'
            }]
          },
          columnWidth: '80%'
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0) + '%'
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#000']
        },
        formatter: function(value: number): string {
          return `${value.toFixed(0)}%`
        }
      },
      title: {
        text: `Separado por ${Grafico.titulo}`
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      yaxis: {
        show: false
        // labels: {
        //   formatter: function (y: any) {
        //     return y.toFixed(0) + '%'
        //   }
        // }
      },
      xaxis: {
        categories: Grafico.labelX
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries([{
      name: '',
      data: Grafico.series[0].dados.map((e: number) => {
        return e
      })
    }])
  }

  useEffect(() => {
    GerarGrafico()
  }, [Grafico])
  return (
        <Flex width={'100%'} id={`chart-${id}`}/>
  )
}
