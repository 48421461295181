/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaFilePdf, FaChartPie } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { IModel } from '../../interfaces'
import { ComparativoChart } from '../../Components/ComparativoChart'

interface iProps {
  Model: IModel
}

export const TabComparativaPesquisa: React.FC<iProps> = ({ Model }) => {
  const [GrupoSelecionado, setGrupoSelecionado] = useState(Model.pesquisaAnterior !== null ? Model.pesquisaAnterior?.id : '')
  const [CompId, setCompId] = useState('')
  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'end'} mb={'2rem'}>
        <Flex flexDir={'column'}>
          <Text>Pesquisa atual: {Model.pesquisa.descricao}</Text>
          <SelectCmp title='Pesquisa a ser comparada' value={CompId} OnChange={setCompId} width={'14rem'}>
            {Model.pesquisaAnterior !== null
              ? Model.filtroPesquisas.map(e => {
                return (
                <option value={e.pesquisaId}>{e.nomePesquisa}</option>
                )
              })
              : <option value={''}>-</option>}
            {}
          </SelectCmp>
          <SelectCmp title='Filtro por demografia' value={GrupoSelecionado} OnChange={setGrupoSelecionado} width={'14rem'}>
            <option value={''}>Todas</option>
            {Model.demografiasFiltroComparativo.map(e => {
              return (
                <option value={e.itemDemografiaId}>{e.pergunta} - {e.nome}</option>
              )
            })}
          </SelectCmp>
        </Flex>
        <ButtonCmp leftIcon={<FaFilePdf/>} onClick={() => {}} VarColor={'Green2'}>Relatório PDF</ButtonCmp>
      </Flex>
      {/* {Model.comparativoDemografia.map((e, i) => {
        if (e.length === 0) {
          return null
        }
        return (
          <Flex flexDir={'column'} gap={'.75rem'}>
            <Flex gap={'.75rem'} alignItems='center'>
              <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
              </Flex>
              <h3>{e[0].titulo}</h3>
            </Flex>
            <ComparativoChart
              dadosA={e[0].dados}
              dadosB={e[1].dados}
              labels={e[0].dados.map(a => a.titulo)}
              id={`comparativo-demografia-${i}`}
            />
          </Flex>
        )
      })} */}

    </>
  )
}
