import { Flex } from '@chakra-ui/react'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'
import { PerguntaPec } from '../../../../../../interfaces'
import { DemografiaDDL, ResultadoAnovaPergunta } from '../../interfaces'
import { TableDiv } from './styles'

interface iProps {
  demografias: DemografiaDDL[]
  perguntasAlavancagem: PerguntaPec[]
  maozinhas: ResultadoAnovaPergunta[]
}

export const TabelaMaozinha: React.FC<iProps> = ({ demografias, perguntasAlavancagem, maozinhas }) => {
  return (
    <Flex width={'100%'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Questão</th>
              {demografias.map(e => {
                return (
                  <th>{e.pergunta}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {perguntasAlavancagem.map((perg, i) => {
              return (
                <tr key={i}>
                  <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {perg.pergunta}
                  </td>
                  {demografias.map(e => {
                    const pergunta = maozinhas.find(a => a.perguntaId === perg.id && a.demografias.find(dem => dem.demografiaId === e.demografiaId) !== null)
                    if (pergunta) {
                      const mao = pergunta.demografias.find(d => d.demografiaId === e.demografiaId)
                      if (mao) {
                        const maozinhas = mao.grafico.filter(f => f.nomeGrupo === e.nome)
                        if (maozinhas.length < 1) {
                          return <td>-</td>
                        }
                        const maozinha = maozinhas[0]

                        if (maozinha) {
                          if (maozinha.praCima) {
                            return (
                              <td><Flex justifyContent={'center'}><FaThumbsUp color='var(--Green2)' size={'1.5rem'}/></Flex></td>
                            )
                          } else {
                            return (
                              <td><Flex justifyContent={'center'}><FaThumbsDown color='var(--Orange)' size={'1.5rem'}/></Flex></td>
                            )
                          }
                        } else {
                          return <td>-</td>
                        }
                      } else {
                        return <td>-</td>
                      }
                    } else {
                      return <td>-</td>
                    }
                  })}
                </tr>
              )
            })}
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}
